<template>
    <ClientNav />

    <el-scrollbar ref="scroll" class="p-r-10" @scroll="scrollEvent" always height="calc(100vh - 30px)">
        <IndexNav v-if="$route.meta.keepAlive === 'index'" :navShadow="navShadow" :navSearch="navSearch" />
        <MainNav v-if="$route.meta.keepAlive === 'main'" />

        <router-view />
    </el-scrollbar>
    
</template>

<script>
import ClientNav from "@/components/layout/ClientNav.vue";
import IndexNav from "@/components/layout/IndexNav.vue";
import MainNav from "@/components/layout/MainNav.vue";

export default {
    name: "App",
    components: {
        ClientNav,
        IndexNav,
        MainNav
    },
    data() {
        return {
            navShadow: false,
            navSearch: false
        }
    },
    created() {
        // 登录状态管理
        if (this.$getUserInfo().token !== '') {
            this.$store.commit('isLoginVal', true) ;

            // 还应该补充下自动换新token逻辑
            
        }
    },
    mounted() {
        // 设置滚动条位置
        let setScrollTop = (top) => {
            this.$refs.scroll.setScrollTop(top) ;
        }
        
        this.$store.commit('setScrollTopVal', setScrollTop) ;
    },
    methods: {
        // 监听滚动事件
        scrollEvent(param) {
            // 监听触底
            if (this.$store.state.bottomLineLoad) {
                this.$store.state.bottomLineLoad(param.scrollTop) ;
            }

            // 监听首页导航阴影状态
            if (param.scrollTop > 0 && !this.navShadow) {
                this.navShadow = true ;
            } else if (param.scrollTop == 0 && this.navShadow) {
                this.navShadow = false ;
            }

            // 监听首页导航搜索框是否显示
            if (param.scrollTop > 390 && !this.navSearch) {
                this.navSearch = true ;
            } else if (param.scrollTop <= 390 && this.navSearch) {
                this.navSearch = false ;
            }
        }
    }
};
</script>

<style lang="scss">
@import "common/common.scss";

</style>
