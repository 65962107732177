<template>
    <ShadeLayer :show="show" @showEmit="showValue">
        <div class="category-all" @click.stop>
            <div class="category-all-main">
                <div class="category-type">
                    <div class="category-type-item" @click="$router.push({name: 'list', params: {type: 1, scene: 0, purpose: 0}})">
                        <img src="https://cdn-prd.daozi.com/static/client/index/all_ppt.png" />
                        <span class="m-t-3">演示</span>
                    </div>
                    <div class="category-type-item" @click="$router.push({name: 'list', params: {type: 3, scene: 0, purpose: 0}})">
                        <img src="https://cdn-prd.daozi.com/static/client/index/all_excel.png" />
                        <span class="m-t-3">表格</span>
                    </div>
                    <div class="category-type-item" @click="$router.push({name: 'list', params: {type: 2, scene: 0, purpose: 0}})">
                        <img src="https://cdn-prd.daozi.com/static/client/index/all_word.png" />
                        <span class="m-t-3">文字</span>
                    </div>
                    <div class="category-type-item" @click="$router.push({name: 'list', params: {type: 4, scene: 0, purpose: 0}})">
                        <img src="https://cdn-prd.daozi.com/static/client/index/all_design.png" />
                        <span class="m-t-3">设计</span>
                    </div>
                </div>

                <div class="category-scene">
                    <el-scrollbar always height="100%">
                        <div v-for="type in typeAll" :key="type.id">
                            <div class="flex m-b-20" v-for="scene in type.list" :key="scene.id">
                                <span class="category-scene-view" @click="$router.push({name: 'list', params: {type: type.id, scene: scene.id, purpose: 0}})">
                                    {{scene.name}}
                                </span>
                                <span class="category-purpose-view m-r-15" v-for="purpose in scene.list" :key="purpose.id"
                                    @click="$router.push({name: 'list', params: {type: type.id, scene: scene.id, purpose: purpose.id}})">
                                    {{purpose.name}}
                                </span>
                            </div>
                        </div>
                    </el-scrollbar>
                </div>
            </div>

            <div class="cursor m-l-15" @click="shadeClose">
                <img src="https://cdn-prd.daozi.com/static/client/detail/detail_close.png" />
            </div>
        </div>
    </ShadeLayer>
</template>

<script>
import ShadeLayer from "@/components/layer/ShadeLayer.vue";

export default {
    name: "CategoryAll",
    components: {
        ShadeLayer
    },
    data() {
        return {
            typeAll: []
        };
    },
    props: ['show'],
    created() {
        this.getTypeAll() ;
    },
    methods: {
        // 关闭
        shadeClose() {
            this.$emit('showEmit', false) ;
        },
        // 是否显示
        showValue(data) {
            this.$emit('showEmit', data) ;
        },
        // 获取首页关键词列表
        getTypeAll() {
            const url = this.$conf.apiUrl + '/client/Material/typeAll' ;

            this.$axios.get(url).then((res) => {
                this.typeAll = res.data.data ;
            }) ;
        }

    }
};
</script>

<style scoped lang="scss">
/* 布局 div */
.category-all {
    display: flex;
    align-items: flex-start;
    width: 1000px;
    height: 550px;
    margin-bottom: 30px;
}
.category-all-main {
    display: flex;
    flex: 1;
    height: 100%;
    padding: 20px 0px 20px 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
}

/* 类型列表 */
.category-type {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100px;
    height: 100%;
}
.category-type-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.category-type-item>img {
    width: 32px;
    height: 32px;
}
.category-type-item>span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #333333;
}
.category-type-item:hover>span {
    color: #ff8e41;
}

/* 场景和用途列表 */
.category-scene {
    flex: 1;
    padding: 10px 0px 10px 20px;
}
.category-scene-view {
    width: 100px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    cursor: pointer;
}
.category-scene-view:hover {
    color: #ff8e41;
}
.category-purpose-view {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #666666;
    cursor: pointer;
}
.category-purpose-view:hover {
    color: #ff8e41;
}


</style>
