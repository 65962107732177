<template>
    <div class="category-nav">
        <div class="nav-element">
            <span class="nav-element-title">分类：</span>
            <div class="nav-element-cut"></div>
            <span class="nav-element-span" v-for="item in typeList" :key="item.id" :class="typeIndex(item.id)" @click="setType(item.id)">
                {{item.title}}
            </span>
        </div>
        <div class="nav-element m-t-20" v-if="category.type !== 0">
            <span class="nav-element-title">场景：</span>
            <div class="nav-element-cut"></div>
            <span class="nav-element-span" :class="sceneIndex(0)" @click="setScene(0)">全部</span>
            <span class="nav-element-span" v-for="item in sceneList" :key="item.id" :class="sceneIndex(item.id)" @click="setScene(item.id)">
                {{item.title}}
            </span>
        </div>
        <div class="nav-element m-t-20" v-if="category.scene !== 0">
            <span class="nav-element-title">用途：</span>
            <div class="nav-element-cut"></div>
            <span class="nav-element-span" :class="purposeIndex(0)" @click="setPurpose(0)">全部</span>
            <span class="nav-element-span" v-for="item in purposeList" :key="item.id" :class="purposeIndex(item.id)" @click="setPurpose(item.id)">
                {{item.title}}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "CategoryNav",
    data() {
        return {
            typeList: [{'id': 1, 'title': 'PPT模板'}, {'id': 2, 'title': 'Word模板'}, {'id': 3, 'title': 'Excel表格'}, {'id': 4, 'title': '广告设计'}],
            sceneList: [],
            purposeList: []
        };
    },
    props: ['category'],
    created() {
        // 初始化分类、场景列表
        if (this.category.type !== 0) {
            this.getSceneList() ;
        }
        if (this.category.scene !== 0) {
            this.getPurposeList() ;
        }
        
    },
    computed: {
        typeIndex() {
            return (typeId) => {
                return { 'active': typeId === this.category.type } ;
            }
        },
        sceneIndex() {
            return (sceneId) => {
                return { 'active': sceneId === this.category.scene } ;
            }
        },
        purposeIndex() {
            return (purposeId) => {
                return { 'active': purposeId === this.category.purpose } ;
            }
        }
    },
    methods: {
        // 读取场景列表
        getSceneList() {
            const url = this.$conf.apiUrl + '/client/Material/scene' ;

            this.$axios.post(url, this.$qs.stringify({
                type_id: this.category.type
            })) .then((res) => {
                this.sceneList = res.data.data ;
            }) ;
        },
        // 读取用途列表
        getPurposeList() {
            const url = this.$conf.apiUrl + '/client/Material/purpose' ;

            this.$axios.post(url, this.$qs.stringify({
                scene_id: this.category.scene
            })) .then((res) => {
                this.purposeList = res.data.data ;
            }) ;
        },
        // 选择类型
        setType(index) {
            // 设置当前类型
            this.category.type = index ;

            // 初始化当前场景、用途
            this.category.scene   = 0 ;
            this.category.purpose = 0 ;

            // 读取场景列表
            this.getSceneList() ;

            // 初始化用途列表
            this.purposeList = [] ;

            // 分类变更事件
            this.$emit('categoryEmit', this.category) ;
        },
        // 选择场景
        setScene(index) {
            // 设置当前场景
            this.category.scene = index ;

            // 初始化当前用途
            this.category.purpose = 0 ;

            // 用途列表
            if (index !== 0) {
                // 读取用途列表
                this.getPurposeList() ;
            } else {
                // 初始化用途列表
                this.purposeList = [] ;
            }

            // 分类变更事件
            this.$emit('categoryEmit', this.category) ;
        },
        // 选择用途
        setPurpose(index) {
            // 设置当前用途
            this.category.purpose = index ;

            // 分类变更事件
            this.$emit('categoryEmit', this.category) ;
        }

    }
};
</script>

<style scoped lang="scss">
// 内容元素
.nav-element {
    display: flex;
    align-items: center;
}
.nav-element-title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #666666;
}
.nav-element-span {
    margin: 0 10px;
    padding: 3px 7px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #333333;
    cursor: pointer;
}
.nav-element-span:hover {
    color: #666666;
}
.nav-element-span.active {
    background: linear-gradient(102deg, #FCB035, #FF8051);
    color: white;
    border-radius: 8px;
}

// 分隔线
.nav-element-cut {
    margin: 0px 5px;
    width: 1px;
    height: 22px;
    background-color: #c8c8c8;
}

</style>
