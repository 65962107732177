<template>
    <WhiteLayer :show="$store.state.vipPayShow">
        <div class="vip-pay">
            <div class="pay-left">
                <div class="flex is-column cross-start">
                    <span class="text-dark-26 m-t-20">会员中心</span>
                    <span class="text-dark-16 m-t-20">成为会员专享会员特权</span>
                </div>
                <div class="m-t-65">
                    <div class="flex axis-between">
                        <div class="flex is-column vip-left-item">
                            <img src="https://cdn-prd.daozi.com/static/client/pay/left_icon01.png" />
                            <span class="text-dark-14 m-t-10">海量PPT模板</span>
                        </div>
                        <div class="flex is-column vip-left-item">
                            <img src="https://cdn-prd.daozi.com/static/client/pay/left_icon02.png" />
                            <span class="text-dark-14 m-t-10">高速下载通道</span>
                        </div>
                    </div>
                    <div class="flex axis-between m-t-15">
                        <div class="flex is-column vip-left-item">
                            <img src="https://cdn-prd.daozi.com/static/client/pay/left_icon03.png" />
                            <span class="text-dark-14 m-t-10">尊贵身份标识</span>
                        </div>
                        <div class="flex is-column vip-left-item">
                            <img src="https://cdn-prd.daozi.com/static/client/pay/left_icon04.png" />
                            <span class="text-dark-14 m-t-10">一对一客服</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pay-right">
                <div class="width-full flex axis-end">
                    <Close class="close-icon" @click="layerClose" />
                </div>

                <div class="flex">
                    <img class="user-portrait" :src="$getUserInfo().headimgurl" />
                    <span class="text-light-16 m-l-5 m-t-5">{{$getUserInfo().nickname}}</span>
                    <div class="flex-one flex axis-center">
                        <span class="text-light-14 m-l-10 m-t-5">申请发票（确认购买后，申请发票请在一个月内提交申请）</span>
                    </div>
                </div>

                <div class="flex axis-between m-t-30 p-15">
                    <div class="vip-pay-item" :class="[classThisNow(3)]" @click="chooseVip(3)">
                        <div class="vip-item-discount discount-one" v-if="isFirst">
                            <span class="text-white-14">首单折扣</span>
                        </div>
                        <span class="text-16">{{vipConf[3]['title']}}</span>
                        <div class="m-t-10">
                            <span class="text-red-36">{{vipConf[3]['price']}}</span>
                            <span class="text-red-16">元</span>
                        </div>
                        <span class="text-light-16 m-t-10">{{vipConf[3]['limit']}}</span>
                        <span class="text-light-14 m-t-7">{{vipConf[3]['describe']}}</span>
                    </div>

                    <div class="vip-pay-item" :class="[classThisNow(2)]" @click="chooseVip(2)">
                        <div class="vip-item-discount discount-two" v-if="isFirst">
                            <span class="text-white-14">首单折扣</span>
                        </div>
                        <span class="text-16">{{vipConf[2]['title']}}</span>
                        <div class="m-t-10">
                            <span class="text-red-36">{{vipConf[2]['price']}}</span>
                            <span class="text-red-16">元</span>
                        </div>
                        <span class="text-light-16 m-t-10">{{vipConf[2]['limit']}}</span>
                        <span class="text-light-14 m-t-7">{{vipConf[2]['describe']}}</span>
                    </div>
                    
                    <div class="vip-pay-item" :class="[classThisNow(1)]" @click="chooseVip(1)">
                        <div class="vip-item-discount discount-three" v-if="isFirst">
                            <span class="text-white-14">首单折扣</span>
                        </div>
                        <span class="text-16">{{vipConf[1]['title']}}</span>
                        <div class="m-t-10">
                            <span class="text-red-36">{{vipConf[1]['price']}}</span>
                            <span class="text-red-16">元</span>
                        </div>
                        <span class="text-light-16 m-t-10">{{vipConf[1]['limit']}}</span>
                        <span class="text-light-14 m-t-7">{{vipConf[1]['describe']}}</span>
                    </div>
                </div>

                <div class="flex axis-between p-15">
                    <div class="flex is-column cross-start">
                        <div>
                            <span class="text-16">应付金额：</span>
                            <span class="text-red-20">{{priceNow}}</span>
                            <span class="text-red-14">元</span>
                        </div>
                        <span class="text-16 m-t-10">请使用微信/支付宝扫码支付</span>
                        <span class="text-light-14 m-t-10">支付代表接受《VIP服务协议》</span>
                    </div>
                    <div class="flex">
                        <div class="flex is-column">
                            <div class="vip-pay-qrcode">
                                <qrcode-vue :value="wechatQrcode" size="90" level="H" v-if="qrcodeShow" />
                            </div>
                            <div class="flex">
                                <img src="https://cdn-prd.daozi.com/static/client/pay/wechat_pay.png" />
                                <span class="text-wechat m-l-5">微信支付</span>
                            </div>
                        </div>
                        <div class="flex is-column m-l-50">
                            <div class="vip-pay-qrcode">
                                <qrcode-vue :value="aliQrcode" size="90" level="H" v-if="qrcodeShow" />
                            </div>
                            <div class="flex">
                                <img src="https://cdn-prd.daozi.com/static/client/pay/ali_pay.png" />
                                <span class="text-ali m-l-5">支付宝支付</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </WhiteLayer>
</template>

<script>
import { Close } from '@element-plus/icons-vue'
import QrcodeVue from 'qrcode.vue'
import WhiteLayer from "@/components/layer/WhiteLayer.vue";

export default {
    name: "VipPay",
    components: {
        Close,
        QrcodeVue,
        WhiteLayer
    },
    data() {
        return {
            vipConf: {},
            isFirst: false,
            vipTypeNow: 3,
            vipPayTiming: null,
            uniqueId: 0,
            wechatQrcode: '',
            aliQrcode: '',
            qrcodeShow: false
        }
    },
    created() {
        // 给定默认值，避免报错
        this.getVipConf() ;
    },
    mounted() {
        // 挂载读取微信登录状态
        this.$store.commit('vipPayInitVal', this.vipPayInit) ;
    },
    unmounted() {
        this.$store.commit('vipPayInitVal', null) ;

        // 若有定时，关闭，同时设置微信登录全局显示状态
        if (this.vipPayTiming) {
            clearInterval(this.vipPayTiming) ;
            this.$store.commit('vipPayShowVal', false) ;
        }
    },
    computed: {
        // 当前选择的价格
        priceNow() {
            let price = 299 ;

            switch (this.vipTypeNow) {
                case 1:
                    price = this.vipConf[1]['price'] ;
                    break ;
                case 2:
                    price = this.vipConf[2]['price'] ;
                    break ;
                case 3:
                    price = this.vipConf[3]['price'] ;
                    break ;
            }

            return price ;
        },
        // 当前选中样式
        classThisNow() {
            return (type) => {
                let resClass = '' ;

                if (type === this.vipTypeNow) {
                    resClass = 'this' ;
                }
                
                return resClass ;
            }
        }
    },
    methods: {
        layerClose() {
            this.$store.commit('vipPayShowVal', false) ;
        },
        // 初始化触发
        vipPayInit(val) {
            if (val) {
                // VIP价格配置
                this.getVipConf() ;

                // 支付二维码
                this.getQrcode() ;
            }

            // 定时
            if (val) {
                // 开启定时
                this.vipPayTiming = setInterval(this.getPayStatus, 1000) ;
            } else {
                // 关闭定时
                clearInterval(this.vipPayTiming) ;
            }
        },
        // 读取VIP价格配置
        getVipConf() {
            const url = this.$conf.apiUrl + '/client/Person/vipPayConf' ;

            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token
            })) .then((res) => {
                this.vipConf = res.data.data ;
                this.isFirst = res.data.is_first ;
            }) ;
        },
        // 选择VIP类型
        chooseVip(type) {
            // 设置当前VIP类型
            this.vipTypeNow = type ;

            // 重新获取支付二维码
            this.getQrcode() ;
        },
        // 获取支付二维码
        getQrcode() {
            this.qrcodeShow = false ;

            const url = this.$conf.apiUrl + '/client/Person/getPayQrcode' ;

            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token,
                product_id: this.vipTypeNow
            })) .then((res) => {
                if (res.data.code) {
                    this.uniqueId = res.data.data.uniq_id ;
                    this.wechatQrcode = res.data.data.wechat ;
                    this.aliQrcode = res.data.data.ali ;

                    this.qrcodeShow = true ;
                }
            }) ;
        },
        // 查询支付状态
        getPayStatus() {
            const url = this.$conf.apiUrl + '/client/Person/getPayStatus' ;

            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token,
                uniq_id: this.uniqueId
            })) .then((res) => {
                if (res.data.code && res.data.is_pay) {
                    // 支付完成，更新VIP状态，并关闭支付界面
                    let userInfo = this.$getUserInfo() ;
                    userInfo.vip_info = res.data.data ;
                    this.$setUserInfo(userInfo) ;

                    this.$store.commit('vipPayShowVal', false) ;
                }
            }) ;
        }
    }
};
</script>

<style scoped lang="scss">
/* 布局 div */
.vip-pay {
    display: flex;
    width: 860px;
    height: 460px;
    margin-bottom: 30px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
}

/* 关闭区域 */
.close-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.close-icon:hover {
    color: #FF8051;
}

/* 字体 */
.text-dark-26 {
    font-size: 26px;
    font-weight: bold;
    font-family: Microsoft YaHei;
    color: #221F11;
}
.text-dark-16 {
    font-size: 16px;
    font-weight: bold;
    font-family: Microsoft YaHei;
    color: #221F11;
}
.text-dark-14 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #221F11;
}

.text-light-16 {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #666666;
}
.text-light-14 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #666666;
}

.text-red-36 {
    font-size: 36px;
    font-weight: bold;
    font-family: Microsoft YaHei;
    color: #F52F01;
}
.text-red-20 {
    font-size: 20px;
    font-weight: bold;
    font-family: Microsoft YaHei;
    color: #F52F01;
}
.text-red-16 {
    font-size: 16px;
    font-weight: bold;
    font-family: Microsoft YaHei;
    color: #F52F01;
}
.text-red-14 {
    font-size: 14px;
    font-weight: bold;
    font-family: Microsoft YaHei;
    color: #F52F01;
}

.text-white-14 {
    line-height: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #FFFFFF;
}

.text-wechat {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #00C204;
}
.text-ali {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #1676FF;
}

/* 左侧区域 */
.pay-left {
    width: 245px;
    height: 100%;
    padding: 30px 25px;
    box-sizing: border-box;
    background: url(https://cdn-prd.daozi.com/static/client/pay/left_bg.png) center no-repeat;
    background-size: 100% 100%;
}

.vip-left-item {
    width: 85px;
}

/* 右侧区域 */
.pay-right {
    flex: 1;
    padding: 10px 15px 0px 15px;
}

.user-portrait {
    width: 40px;
    border-radius: 20px;
}

.vip-pay-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px;
    width: 160px;
    box-sizing: border-box;
    border: 1px solid #DCDCDC;
    border-radius: 8px;
    cursor: pointer;
}
.vip-pay-item.this {
    border: 1px solid #FF8E41;
}
.vip-item-discount {
    position: absolute;
    top: -19px;
    left: -8px;
    width: 116px;
    height: 33px;
    text-align: center;
}
.discount-one {
    background: url(https://cdn-prd.daozi.com/static/client/pay/discount_bg01.png) center no-repeat;
    background-size: 100% 100%;
}
.discount-two {
    background: url(https://cdn-prd.daozi.com/static/client/pay/discount_bg02.png) center no-repeat;
    background-size: 100% 100%;
}
.discount-three {
    background: url(https://cdn-prd.daozi.com/static/client/pay/discount_bg03.png) center no-repeat;
    background-size: 100% 100%;
}

.vip-pay-qrcode {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
}

</style>
