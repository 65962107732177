<template>
    <div class="main-nav main-nav-shadow">
        <div class="container height-full flex axis-between">
            <div class="flex">
                <img src="https://cdn-prd.daozi.com/static/client/nav/logo.png" class="nav-logo" @click="$router.push('/')" />

                <div class="flex m-l-30">
                    <span class="text-16 cursor" @click="$router.push('/')">首页</span>
                    <div class="nav-list m-l-30">
                        <div class="nav-list-title p-b-10">
                            <span>分类</span>
                            <ArrowDown class="nav-list-icon m-l-3 m-t-1" />
                        </div>
                        <div class="nav-list-div m-l-m-30">
                            <span @click="listTo(1)">PPT模板</span>
                            <span @click="listTo(2)">Word模板</span>
                            <span @click="listTo(3)">Excel模板</span>
                            <span @click="listTo(4)">广告设计</span>
                        </div>
                    </div>
                </div>

                <div class="nav-search">
                    <div class="nav-list nav-list-amend">
                        <div class="nav-list-title p-b-16">
                            <span>{{searchTypeShow}}</span>
                            <ArrowDown class="nav-list-icon m-l-3 m-t-1" />
                            <div class="nav-search-line m-l-20 m-r-20"></div>
                        </div>
                        <div class="nav-list-div nav-list-div-amend m-l-m-23">
                            <span @click="setSearchType(0)">全部</span>
                            <span @click="setSearchType(1)">PPT模板</span>
                            <span @click="setSearchType(2)">Word模板</span>
                            <span @click="setSearchType(3)">Excel模板</span>
                            <span @click="setSearchType(4)">广告设计</span>
                        </div>
                    </div>

                    <div class="nav-search-input">
                        <input type="text" v-model="searchKey" @keyup.enter="searchTo" placeholder="搜索优质ppt和办公模板">
                    </div>

                    <div class="nav-search-button" @click="searchTo">
                        <img src="https://cdn-prd.daozi.com/static/imagel/search_icon.png" />
                    </div>
                </div>
            </div>
            
            <!-- 未登录样式 -->
            <div class="nav-login cursor m-r-5" @click="wechatOpen" v-if="!$store.state.isLogin">
                <img src="https://cdn-prd.daozi.com/static/client/nav/login.png" />
                <span class="text-16 m-l-5">注册/登录</span>
            </div>

            <!-- 登录后样式 -->
            <div class="nav-login" v-if="$store.state.isLogin">
                <div class="nav-vip cursor" @click="vipPayOpen">
                    <img class="nav-vip-show" src="https://cdn-prd.daozi.com/static/images/index/vip.svg" />
                    <img class="nav-vip-tip" src="https://cdn-prd.daozi.com/static/images/vip-tip.svg" />
                </div>
                <div class="nav-user cursor m-l-5">
                    <div class="flex cross-end axis-center p-b-7">
                        <img class="nav-user-head" :src="$getUserInfo().headimgurl" />
                        <img class="nav-user-mark" :src="userMark" v-if="userMark !== ''" />
                    </div>
                    <div class="nav-user-menu">
                        <span class="text-16 text-hover" @click="$router.push('/user')">个人中心</span>
                        <span class="text-16 text-hover m-t-15" @click="logout">退出登录</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { ArrowDown } from '@element-plus/icons-vue'

export default {
    name: "MainNav",
    components: {
        ArrowDown
    },
    data() {
        return {
            searchKey: '',
            searchType: 0,
            searchTypeShow: '全部'
        }
    },
    created() {
        // 获取参数
        if (this.$route.params.search) {
            this.searchKey = this.$route.params.search ;

            if (this.$route.params.type) {
                this.setSearchType(parseInt(this.$route.params.type)) ;
            }
        }
    },
    computed: {
        userMark() {
            let mark = '' ;

            if (this.$store.state.isLogin) {
                switch (this.$getUserInfo().vip_info.vip_type) {
                    case 1:
                        mark = 'https://cdn-prd.daozi.com/static/client/nav/vip1.png' ;
                        break ;
                    case 2:
                        mark = 'https://cdn-prd.daozi.com/static/client/nav/vip2.png' ;
                        break ;
                    case 3:
                        mark = 'https://cdn-prd.daozi.com/static/client/nav/vip3.png' ;
                        break ;
                }
            }

            return mark ;
        }
    },
    methods: {
        // 搜索
        searchTo() {
            this.$router.push({name: 'empty', params: {page: 'list', type: this.searchType, search: this.searchKey}}) ;
        },
        // 跳转到列表页
        listTo(param) {
            this.$router.push({name: 'empty', params: {page: 'list', type: param, scene: 0, purpose: 0}}) ;
        },
        // 转换显示的搜索类型
        setSearchType(type) {
            this.searchType = type ;

            switch (type) {
                case 0:
                    this.searchTypeShow = '全部' ;
                    break ;
                case 1:
                    this.searchTypeShow = 'PPT模板' ;
                    break ;
                case 2:
                    this.searchTypeShow = 'Word模板' ;
                    break ;
                case 3:
                    this.searchTypeShow = 'Excel模板' ;
                    break ;
                case 4:
                    this.searchTypeShow = '广告设计' ;
                    break ;
                default:
                    this.searchTypeShow = '全部' ;
                    break ;
            }
        },
        // 登录界面显示
        wechatOpen() {
            this.$store.commit('wechatLoginShowVal', true) ;
        },
        // 退出登录
        logout() {
            this.$setUserInfo('') ;
            this.$store.commit('isLoginVal', false) ;

            // 退出后触发各页面的刷新事件
            if (this.$store.state.loginEvent) {
                this.$store.state.loginEvent() ;
            }
        },
        // 支付界面显示
        vipPayOpen() {
            this.$store.commit('vipPayShowVal', true) ;
        }
    }
};
</script>

<style scoped lang="scss">
/* 最外层 div */
.main-nav {
    position: sticky;
    top: 0px;
    width: 100%;
    height: 68px;
    z-index: 100;
    background-color: #ffffff;
}
.main-nav-shadow {
    box-shadow: 0px 5px 5px 0px rgba(33, 33, 33, 0.16);
}

/* 导航 logo */
.nav-logo {
    cursor: pointer;
}

/* 分类区域 */
.nav-list {
    display: flex;
    flex-direction: column;
    width: 90px;
    height: 21px;
    cursor: pointer;
}
.nav-list-title {
    display: flex;
    align-items: center;
}
.nav-list-title>span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #333333;
}
.nav-list-icon {
    width: 16px;
    height: 16px;
}
.nav-list-div {
    display: none;
    width: 111px;
    padding: 10px 20px;
    box-sizing: border-box;
    background-color: #F9F9F9;
    color: #333333;
    border-radius: 12px;
    box-shadow: 0px 1px 9px 0px rgba(5, 5, 5, 0.22);
}
.nav-list:hover>.nav-list-div {
    display: flex;
    flex-direction: column;
}
.nav-list>.nav-list-div:hover {
    display: flex;
    flex-direction: column;
}
.nav-list-div>span {
    line-height: 40px;
    font-size: 15px;
}
.nav-list-div>span:hover {
    color: #ff8e41;
}

.m-l-m-30 {
    margin-left: -30px;
}

/* 搜索区域 */
.nav-search {
    display: flex;
    align-items: center;
    padding-left: 20px;
    width: 735px;
    height: 46px;
    box-sizing: border-box;
    font-size: 16px;
    color: #666666;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #ff8e41;
    border-radius: 6px;
}
@media only screen and (max-width: 1850px) {
    .nav-search {
        width: 600px;
    }
}
@media only screen and (max-width: 1450px) {
    .nav-search {
        width: 450px;
    }
}

.m-l-m-23 {
    margin-left: -23px;
}
.nav-list-amend {
    width: fit-content;
}
.nav-list-div-amend {
    border-radius: 6px;
}
.nav-search-line {
    width: 1px;
    height: 20px;
    background: #C1C1C1;
}

.nav-search-input {
    flex: 1;
}
.nav-search-input>input {
    width: 100%;
    height: 40px;
    padding-left: 5px;
    font-size: 16px;
    border: none;
    outline: 0px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0);
}
.nav-search-input>input::placeholder {
    color: #666666;
}

.nav-search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 46px;
    background-color: #ff8e41;
    border-radius: 0px 6px 6px 0px;
    cursor: pointer;
}
.nav-search-button:hover {
    opacity: 0.9;
}
.nav-search-button>img {
    width: 25px;
    height: 25px;
}

/* 登录注册区域 */
.nav-login {
    display: flex;
    align-items: center;
    height: 100%;
}

.nav-vip {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 28px;
}
.nav-vip-show {
    width: 110px;
    padding-bottom: 15px;
}
.nav-vip-tip {
    display: none;
    position: absolute;
    width: 250px;
    top: 39px;
}
.nav-vip:hover>.nav-vip-tip {
    display: block;
}

.nav-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95px;
    height: 40px;
}
.nav-user-head {
    width: 40px;
    border-radius: 20px;
}
.nav-user-mark {
    width: 22px;
    margin-left: -15px;
}
.nav-user-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px;
    width: 95px;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0px 1px 9px 0px rgba(5, 5, 5, 0.22);
}
.nav-user:hover>.nav-user-menu {
    display: flex;
}


</style>
