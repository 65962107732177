<template>
    <div class="width-full p-b-100">
        <div class="container m-t-30">
            <!-- 面包屑 -->
            <div class="flex m-t-20">
                <span class="text-light-14 text-light-hover" @click="$router.push('/')">首页</span>
                <span class="text-light-14 m-l-5 m-r-5">></span>
                <span class="text-light-14">专题</span>
            </div>
            
            <!-- banner -->
            <div class="m-t-30">
                <img class="theme-banner" :src="themeBanner" />
            </div>

            <!-- 素材列表 -->
            <div class="list-div-main">
                <div class="m-t-30" v-for="(item, key) in listData" :key="key">
                    <ListMaterial :material="item" @recommendEmit="recommendMaterial" />
                </div>
            </div>

            <!-- 触底区域 -->
            <div ref="bottomLine" class="bottom-line width-full flex axis-center m-t-60">
                <span>------------------------------ {{bottomLine}} ------------------------------</span>
            </div>
        </div>

        <!-- 返回顶部 -->
        <div class="to-top" v-if="toTopShow" @click="toTop()">
            <ArrowUpBold class="to-top-icon" />
        </div>
    </div>

    <DetailSingle :show="recommendShow" :detailId="recommendId" @showEmit="recommendValue" @favoriteEmit="favoriteValue" @recommendEmit="recommendSingle" />
</template>

<script>
import { ArrowUpBold } from '@element-plus/icons-vue'
import ListMaterial from "@/components/material/ListMaterial.vue";
import DetailSingle from "@/components/material/DetailSingle.vue";

export default {
    name: "Theme",
    components: {
        ArrowUpBold,
        ListMaterial,
        DetailSingle
    },
    data() {
        return {
            themeId: 16,
            themeBanner: '',
            listData: [],
            page: 1,
            bottomLine: '我是有底线的',
            loadMoreAllow: true,
            toTopShow: false,
            recommendShow: false,
            recommendId: 0
        };
    },
    created() {
        // 获取参数
        if (this.$route.params.themeId) {
            this.themeId = parseInt(this.$route.params.themeId) ;
        }

        // 素材列表
        this.getListData() ;
    },
    mounted() {
        // 挂载触底事件
        this.$store.commit('bottomLineLoadVal', this.loadMore) ;

        // 挂载登录、退出后回调事件
        this.$store.commit('loginEventVal', this.loginEvent) ;
    },
    unmounted() {
        this.$store.commit('bottomLineLoadVal', null) ;
        this.$store.commit('loginEventVal', null) ;
    },
    methods: {
        // 触底加载更多
        loadMore(scrollTop) {
            // 判断是否读取数据
            if (this.loadMoreAllow && this.page < 10) {
                let bottomGap = this.$refs.bottomLine.getBoundingClientRect().top - document.body.clientHeight ;
                
                if (bottomGap < 300) {
                    this.loadMoreAllow = false ;
                    this.page++ ;
                    this.getListData() ;
                }
            }
            
            // 判断是否显示返回顶部
            if (this.toTopShow === false && scrollTop > 1000) {
                this.toTopShow = true ;
            }
            if (this.toTopShow === true && scrollTop < 1000) {
                this.toTopShow = false ;
            }
        },
        // 返回顶部
        toTop() {
            this.$store.state.setScrollTop(0) ;
        },
        // 登录、退出后触发的事件
        loginEvent() {
            const url = this.$conf.apiUrl + '/client/Material/savedRefresh' ;
            
            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token,
                list: this.listData
            })) .then((res) => {
                this.listData = res.data.data ;
            }) ;
        },
        // 读取素材列表
        getListData() {
            const url = this.$conf.apiUrl + '/client/Material/themeList' ;

            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token,
                banner: this.themeBanner,
                theme_id: this.themeId,
                page: this.page
            })) .then((res) => {
                this.themeBanner = res.data.banner ;
                this.listData.push.apply(this.listData, res.data.data) ;
                this.loadMoreAllow = true ;
            }) ;
        },
        // 推荐变更（material）
        recommendMaterial(recommendId) {
            this.recommendId = recommendId ;
        },
        // 推荐变更（single）
        recommendSingle(recommendId) {
            this.recommendId = recommendId ;
            this.recommendShow = false ;
        },
        // 推荐界面显示状态
        recommendValue(data) {
            this.recommendShow = data ;
        },
        // 推荐详情页收藏状态变更触发事件
        favoriteValue(recommendId) {
            for (let val of this.listData) {
                if (val.id === recommendId) {
                    val.saved = !val.saved ;
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
/* 面包屑 */
.text-light-14 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #666666;
}
.text-light-hover:hover {
    color: #ff8e41;
    cursor: pointer;
}

/* banner */
.theme-banner {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.12);
}

/* 素材列表 */
.list-div-main {
    display: grid;
    justify-content: space-between;
	grid-template-columns: auto auto auto auto auto;
}
@media only screen and (max-width: 1450px) {
    .list-div-main {
        grid-template-columns: auto auto auto auto;
    }
}

/* 触底区域 */
.bottom-line>span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #666666;
}

/* 返回顶部 */
.to-top {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 100px;
    right: 25px;
    width: 35px;
    height: 35px;
    z-index: 100;
    border-radius: 35px;
    background-color: #f6f7f9;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}
.to-top-icon {
    width: 20px;
    height: 20px;
    color: #666666;
}
.to-top:hover {
    background-color: #ff8e41;
}
.to-top:hover>.to-top-icon {
    color: #ffffff;
}

</style>
