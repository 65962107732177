<template>
    <div class="material" @click="detailOpen()">
        <div class="material-main" :class="[heightClass]">
            <img :src="material.image" />
            <div class="material-shade">
                <img :src="saveIcon" @click.stop @click="favorite" />
                <span class="m-t-50">查看详情</span>
            </div>
        </div>
        <span class="material-title">{{material.title}}</span>
    </div>

    <Detail :show="detailShow" :detailId="material.id" @showEmit="detailValue" @favoriteEmit="favoriteValue" @recommendEmit="recommendUpdate" />
</template>

<script>
import Detail from "@/components/material/Detail.vue";

export default {
    name: "UserMaterial",
    components: {
        Detail
    },
    data() {
        return {
            detailShow: false,
        }
    },
    props: ['material'],
    computed: {
        // 不同分类设置的高度不同
        heightClass() {
            let resClass = 'material-common' ;

            if (this.material.type === 2) {
                resClass = 'material-high' ;
            }
            if (this.material.type === 4 && this.material.scene === 24) {
                resClass = 'material-high' ;
            }
            if (this.material.type === 4 && this.material.scene === 19) {
                resClass = 'material-inbetweening' ;
            }

            return resClass ;
        },
        // 收藏图标
        saveIcon() {
            if (this.material.saved) {
                return 'https://cdn-prd.daozi.com/static/client/user/star.png' ;
            } else {
                return 'https://cdn-prd.daozi.com/static/client/user/star_empty.png' ;
            }
        }
    },
    methods: {
        detailOpen() {
            this.detailShow = true ;
        },
        detailClose() {
            this.detailShow = false ;
        },
        detailValue(data) {
            this.detailShow = data ;
        },
        // 收藏
        favorite() {
            if (this.$store.state.isLogin) {
                const url = this.$conf.apiUrl + '/client/Person/favorite' ;
                
                this.$axios.post(url, this.$qs.stringify({
                    token: this.$getUserInfo().token,
                    detail_id: this.material.id
                })) .then((res) => {
                    if (res.data.code) {
                        this.material.saved = !this.material.saved ;
                    }
                }) ;
            } else {
                // 未登录，需要先登录
                this.$store.commit('wechatLoginShowVal', true) ;
            }
        },
        // 详情页提交收藏状态变更
        favoriteValue(status) {
            this.material.saved = status ;
        },
        // 推荐变更
        recommendUpdate(recommendId) {
            this.detailShow = false ;

            this.$emit('recommendEmit', recommendId) ;
        }
    }
};
</script>

<style scoped lang="scss">
/* 外框 */
.material {
    display: flex;
    flex-direction: column;
    width: 227px;
    overflow: hidden;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.12);
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    cursor: pointer;
}
@media only screen and (max-width: 1850px) {
    .material {
        width: 185px;
    }
}
@media only screen and (max-width: 1450px) {
    .material {
        width: 165px;
    }
}

/* 外框 hover 效果 */
.material:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 16px 0 rgba(0,0,0,.3);
}

/* 外框 hover 遮罩区域显示 */
.material:hover .material-shade {
    display: flex;
}

/* 图片区域 */
.material-main {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.material-main>img {
    width: 100%;
}

.material-common {
    height: 257px;
}
.material-high {
    height: 320px;
}
.material-inbetweening {
    height: 299px;
}
@media only screen and (max-width: 1850px) {
    .material-common {
        height: 204px;
    }
    .material-high {
        height: 264px;
    }
    .material-inbetweening {
        height: 244px;
    }
}
@media only screen and (max-width: 1450px) {
    .material-common {
        height: 187px;
    }
    .material-high {
        height: 235px;
    }
    .material-inbetweening {
        height: 211px;
    }
}

/* 遮罩区域 */
.material-shade {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
}
.material-shade>img {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 15px;
    right: 15px;
}
.material-shade>span {
    width: 124px;
    line-height: 36px;
    box-sizing: border-box;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    font-size: 18px;
    outline: none;
    border: 1px solid #fff;
    border-radius: 36px;
    cursor: pointer;
}

/* 标题描述 */
.material-title {
    width: 100%;
    padding: 5px 5px 7px 15px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #333333;
}

</style>
