<template>
    <ShadeLayer :show="$store.state.downloadShow" @showEmit="showValue">
        <div class="download-list" @click.stop>
            <div class="download-header">
                <span class="text-20 text-bold">下载</span>
                <Close class="close-icon" @click="shadeClose" />
            </div>
            
            <el-scrollbar height="515px">
                <div class="p-20">
                    <span class="text-16">下载中 ({{downloadProgressNum}})</span>

                    <div class="flex m-t-20" v-for="(item, key) in downloadProgress" :key="key">
                        <img class="download-progress-img" :src="downloadImg(item.type)" />
                        <div class="flex-one m-l-20">
                            <div class="flex axis-between">
                                <span class="text-14">{{item.title}}</span>
                                <span class="download-progress-percent">{{item.percent}}%</span>
                            </div>
                            <div class="m-t-10">
                                <el-progress :percentage="item.percent" :show-text="showText" />
                            </div>
                        </div>
                    </div>

                </div>

                <div class="p-l-20 p-r-20 p-b-20">
                    <div class="flex axis-between">
                        <span class="text-16">已完成 ({{doneListNum}})</span>
                        <span class="download-done-more" @click="toUser">查看更多</span>
                    </div>

                    <div class="flex m-t-20" v-for="(item, key) in doneList" :key="key">
                        <img class="download-progress-img" :src="downloadImg(item.type)" />
                        <div class="flex flex-one">
                            <div class="download-done-file m-l-20">
                                <span class="text-14">{{item.title}}</span>
                                <span class="download-done-filename m-t-5">{{item.filename}}</span>
                            </div>
                            <span class="download-done-time m-l-20">{{item.time}}</span>
                        </div>
                        <div class="flex m-l-20">
                            <Document class="folder-icon" @click="openFile(item.save_path)" />
                            <FolderOpened class="document-icon m-l-5" @click="openFolder(item.folder)" />
                        </div>
                    </div>

                </div>
            </el-scrollbar>
        </div>
    </ShadeLayer>
</template>

<script>
import ipc from "@/common/ipc.js";

import ShadeLayer from "@/components/layer/ShadeLayer.vue";
import { Close, FolderOpened, Document } from '@element-plus/icons-vue'

export default {
    name: "DownloadList",
    components: {
        ShadeLayer,
        Close,
        FolderOpened,
        Document
    },
    data() {
        return {
            showText: false,
            downloadProgress: {},
            doneList: []
        }
    },
    mounted() {
        // 挂载下载开始
        this.$store.commit('downloadStartVal', this.downloadStart) ;

        // ipc监听下载进度
        this.downloadProgressing() ;
        this.downloadDone() ;
        this.downloadCancelled() ;
    },
    unmounted() {
        this.$store.commit('downloadStartVal', null) ;
    },
    computed: {
        // 下载列表的图片
        downloadImg() {
            return (type) => {
                let downloadImgSrc = '' ;

                switch (type) {
                    case 1:
                        downloadImgSrc = 'https://cdn-prd.daozi.com/static/client/user/ppt.png' ;
                        break ;
                    case 2:
                        downloadImgSrc = 'https://cdn-prd.daozi.com/static/client/user/word.png' ;
                        break ;
                    case 3:
                        downloadImgSrc = 'https://cdn-prd.daozi.com/static/client/user/excel.png' ;
                        break ;
                    case 4:
                        downloadImgSrc = 'https://cdn-prd.daozi.com/static/client/user/design.png' ;
                        break ;
                }

                return downloadImgSrc ;
            }
        },
        // 当前下载中数量
        downloadProgressNum() {
            return Object.keys(this.downloadProgress).length ;
        },
        // 当前已完成数量
        doneListNum() {
            return Object.keys(this.doneList).length ;
        }
    },
    methods: {
        shadeClose() {
            this.$store.commit('downloadShowVal', false) ;
        },
        showValue(data) {
            this.$store.commit('downloadShowVal', data) ;
        },
        // 下载进行中
        downloadProgressing() {
            const progressing = (arg) => {
                this.downloadProgress[arg.filename]['percent'] = arg.percent ;
            }

            ipc.downloadProgressing(progressing) ;
        },
        // 下载已完成
        downloadDone() {
            const done = (arg) => {
                let fileData = this.downloadProgress[arg.filename] ;                
                const url = this.$conf.apiUrl + '/client/Person/downloadDone' ;

                this.$axios.post(url, this.$qs.stringify({
                    token: this.$getUserInfo().token,
                    detail_id: fileData.id,
                    save_path: arg.savePath
                })) .then((res) => {
                    if (res.data.code) {
                        // 删除进行中对应素材
                        delete this.downloadProgress[arg.filename] ;

                        // 刷新已完成列表
                        this.getDoneList() ;

                        // 打开文件
                        this.openFile(arg.savePath) ;
                    }
                }) ;

            }

            ipc.downloadDone(done) ;
        },
        // 下载取消
        downloadCancelled() {
            const cancelled = (arg) => {
                delete this.downloadProgress[arg] ;
            }

            ipc.downloadCancelled(cancelled) ;
        },
        // 下载开始
        downloadStart(material) {
            // 初始化已完成
            if (this.doneList.length == 0) {
                this.getDoneList() ;
            }

            // 显示下载管理界面
            this.$store.commit('downloadShowVal', true) ;

            // 添加正在下载的文件列表
            let temKey = material.filename ;

            if (!(temKey in this.downloadProgress)) {
                // 添加列表
                this.downloadProgress[temKey]            = material ;
                this.downloadProgress[temKey]['percent'] = 0 ;
                
                // 通知客户端开始下载
                ipc.downloadStart(material.download) ;
            }
        },
        // 打开文件
        openFile(path) {
            ipc.openFile(path) ;
        },
        // 打开文件
        openFolder(path) {
            ipc.openFolder(path) ;
        },
        // 读取已完成列表
        getDoneList() {
            const url = this.$conf.apiUrl + '/client/Person/downloadDoneList' ;

            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token
            })) .then((res) => {
                if (res.data.code) {
                    this.doneList = res.data.data ;
                }
            }) ;
        },
        // 查看更多
        toUser() {
            this.$store.commit('downloadShowVal', false) ;
            this.$router.push('/user') ;
        }
    }
};
</script>

<style scoped lang="scss">
/* 布局 div */
.download-list {
    width: 850px;
    height: 575px;
    margin-bottom: 30px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
}

/* 覆盖 ShadeLayer.vue 样式 */
.shade-layer {
    z-index: 1100;
}

/* 头部区域 */
.download-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
}

.close-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.close-icon:hover {
    color: #FF8051;
}

/* 进度区域 */
.download-progress-img {
    width: 42px;
    height: 48px;
}
.download-progress-percent {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #EF6F45;
}

/* 已完成区域 */
.download-done-more {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #999999;
    cursor: pointer;
}
.download-done-more:hover {
    color: #FF8E41;
}
.download-done-file {
    display: flex;
    flex-direction: column;
    min-width: 450px;
}
.download-done-filename {
    font-size: 12px;
    font-family: Microsoft YaHei;
    color: #999999;
}
.download-done-time {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #666666;
}
.folder-icon {
    width: 25px;
    height: 25px;
    color: #888888;
    cursor: pointer;
}
.folder-icon:hover {
    color: #FF8E41;
}
.document-icon {
    width: 27px;
    height: 27px;
    color: #888888;
    cursor: pointer;
}
.document-icon:hover {
    color: #FF8E41;
}



</style>
