// 通信实例
const ipcRenderer = window.ipcRenderer ;

// 最小化窗口
const minWindow = () => {
    ipcRenderer.send('minWindow', '') ;
}

// 最大化窗口切换
const maxWindow = () => {
    ipcRenderer.send('maxWindow', '') ;
}

// 关闭窗口
const closeWindow = () => {
    ipcRenderer.send('closeWindow', '') ;
}

// 下载进行中（响应）
const downloadProgressing = (reply) => {
    ipcRenderer.on('downloadProgressing', (event, arg) => {
        reply(arg) ;
    })
}

// 下载已完成（响应）
const downloadDone = (reply) => {
    ipcRenderer.on('downloadDone', (event, arg) => {
        reply(arg) ;
    })
}

// 下载取消（响应）
const downloadCancelled = (reply) => {
    ipcRenderer.on('downloadCancelled', (event, arg) => {
        reply(arg) ;
    })
}

// 下载开始
const downloadStart = (downloadURL) => {
    ipcRenderer.send('downloadStart', downloadURL) ;
}

// 打开文件
const openFile = (path) => {
    ipcRenderer.send('openFile', path) ;
}

// 打开文件
const openFolder = (path) => {
    ipcRenderer.send('openFolder', path) ;
}

// 输出
export default{
    minWindow,
    maxWindow,
    closeWindow,
    downloadProgressing,
    downloadDone,
    downloadCancelled,
    downloadStart,
    openFile,
    openFolder
}
