<template>
    <div class="width-full p-b-100">
        <div class="container m-t-30">
            <!-- 面包屑 -->
            <div class="flex m-t-20">
                <span class="text-light-14 text-light-hover" @click="$router.push('/')">首页</span>
                <span class="text-light-14 m-l-5 m-r-5">></span>
                <span class="text-light-14">用户中心</span>
            </div>
            
            <div class="flex cross-start axis-between m-t-20">
                <!-- 左侧导航 -->
                <div>
                    <div class="left-nav">
                        <img class="left-nav-portrait" :src="$getUserInfo().headimgurl" />
                        <span class="left-nav-type m-t-10">{{loginType}}</span>
                        <span class="left-nav-id m-t-5">稻子号：{{$getUserInfo().user_id}}</span>
                        <span class="left-nav-bind m-t-7" @click="bindPhoneOpen()">绑定手机</span>
                    </div>

                    <div class="left-nav m-t-20">
                        <p class="left-nav-item m-0" :class="{'active': viewItemNow('favorite')}" @click="chooseItem('favorite')">我的收藏</p>
                        <p class="left-nav-item m-0 m-t-15" :class="{'active': viewItemNow('download')}" @click="chooseItem('download')">我的下载</p>
                        <p class="left-nav-item m-0 m-t-15" :class="{'active': viewItemNow('userVip')}" @click="chooseItem('userVip')">我的会员</p>
                    </div>
                </div>

                <!-- 内容区域 -->
                <div class="user-main flex-one m-l-20">
                    <UserFavorite v-if="viewItemNow('favorite')" />
                    <UserDowload v-if="viewItemNow('download')" />
                    <UserVip v-if="viewItemNow('userVip')" />
                </div>
            </div>
        </div>
    </div>

    <UserPhone :show="userPhoneShow" @showEmit="bindPhoneValue" />
</template>

<script>
import UserFavorite from "@/components/user/UserFavorite.vue"
import UserDowload from "@/components/user/UserDowload.vue"
import UserVip from "@/components/user/UserVip.vue"
import UserPhone from "@/components/user/UserPhone.vue";

export default {
    name: "User",
    components: {
        UserFavorite,
        UserDowload,
        UserVip,
        UserPhone
    },
    data() {
        return {
            viewItem: 'favorite',
            userPhoneShow: false
        };
    },
    created() {
        // 未登录，直接跳转到首页
        if (!this.$store.state.isLogin) {
            this.$router.push('/') ;
        }
    },
    mounted() {
        // 挂载登录、退出后回调事件
        this.$store.commit('loginEventVal', this.loginEvent) ;
    },
    unmounted() {
        this.$store.commit('loginEventVal', null) ;
    },
    computed: {
        // 当前显示内容
        viewItemNow() {
            return (item) => {
                if (item === this.viewItem) {
                    return true ;
                } else {
                    return false ;
                }
            }
        },
        // 当前显示内容
        loginType() {
            let userType = '默认用户' ;

            switch (this.$getUserInfo().login_type) {
                case 'wechat':
                    userType = '微信用户' ;
                    break ;
                case 'phone':
                    userType = '手机用户' ;
                    break ;
                case 'qq':
                    userType = 'QQ用户' ;
                    break ;
            }

            return userType ;
        }
    },
    methods: {
        // 选择项目
        chooseItem(item) {
            this.viewItem = item ;
        },
        // 绑定手机号界面
        bindPhoneOpen() {
            this.userPhoneShow = true ;
        },
        bindPhoneClose() {
            this.userPhoneShow = false ;
        },
        bindPhoneValue(data) {
            this.userPhoneShow = data ;
        },
        // 登录、退出后触发的事件
        loginEvent() {
            this.$router.push('/') ;
        }
    }
};
</script>

<style scoped lang="scss">
/* 面包屑 */
.text-light-14 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #666666;
}
.text-light-hover:hover {
    color: #ff8e41;
    cursor: pointer;
}

/* 左侧导航 */
.left-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    padding: 20px 0px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.12);
}

.left-nav-portrait {
    width: 76px;
    height: 76px;
    border-radius: 50px;
}
.left-nav-type {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}
.left-nav-id {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #999999;
}
.left-nav-bind {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #FF8E41;
    cursor: pointer;
}

.left-nav-item {
    width: 95%;
    line-height: 25px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
}
.left-nav-item.active {
    border-left: 2px solid #FDA53B;
    color: #FF8E41;
}

/* 内容区域 */
.user-main {
    min-height: 500px;
    background: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.12);
}


</style>
