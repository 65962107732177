<template>
    <ShadeLayer :show="$store.state.qqLoginShow" @showEmit="showValue">
        <div class="qq-login" @click.stop>
            <iframe class="qq-login-iframe" :src="qqUrl"></iframe>
        </div>
    </ShadeLayer>
</template>

<script>
import ShadeLayer from "@/components/layer/ShadeLayer.vue";

export default {
    name: "QqLogin",
    components: {
        ShadeLayer
    },
    data() {
        return {
            qqUrl: '',
            qqId: 0,
            qqTiming: null
        }
    },
    mounted() {
        // 挂载读取QQ登录状态
        this.$store.commit('getQqLoginStatusVal', this.getQqLoginStatus) ;
    },
    unmounted() {
        this.$store.commit('getQqLoginStatusVal', null) ;

        // 若有定时，关闭，同时设置QQ登录全局显示状态
        if (this.qqTiming) {
            clearInterval(this.qqTiming) ;
            this.$store.commit('qqLoginShowVal', false) ;
        }
    },
    methods: {
        shadeClose() {
            this.$store.commit('qqLoginShowVal', false) ;
        },
        showValue(data) {
            this.$store.commit('qqLoginShowVal', data) ;
        },
        // QQ登录状态（定时）
        getQqLoginStatus(val) {
            // 定时
            if (val) {
                this.getQqLoginState() ;
            } else {
                this.qqUrl = '' ;
                clearInterval(this.qqTiming) ;
            }
        },
        // QQ登录状态（初始）
        getQqLoginState() {
            const url = this.$conf.apiUrl + '/client/Login/qqLogin' ;

            this.$axios.get(url).then((res) => {
                this.qqUrl = 'https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=101941474&state=clientqq'+res.data.data+'_https://www.daozi.com&redirect_uri=https%3a%2f%2fwww.daozi.com%2findex%2fQqlogin%2fnotify.html' ;
                this.qqId = res.data.data ;
                this.qqTiming = setInterval(this.qqLoginPost, 1000) ;
            }) ;
        },
        // QQ登录状态（读取）
        qqLoginPost() {
            const url = this.$conf.apiUrl + '/client/Login/qqLoginPost' ;

            this.$axios.post(url, this.$qs.stringify({
                id: this.qqId
            })) .then((res) => {
                if (res.data.code) {
                    this.$setUserInfo(res.data.data) ;
                    this.$store.commit('isLoginVal', true) ;
                    this.$store.commit('qqLoginShowVal', false) ;

                    // 登录后触发各页面的刷新事件
                    if (this.$store.state.loginEvent) {
                        this.$store.state.loginEvent() ;
                    }
                }
            }) ;
        }
    }
};
</script>

<style scoped lang="scss">
/* 布局 div */
.qq-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    height: 600px;
    margin-bottom: 30px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #fff;
    border-radius: 10px;
}

.qq-login-iframe {
    width: 100%;
    height: 100%;
}


</style>
