<script>
export default {
    name: "Empty",
    created() {
        // 带着参数跳转到指定页面
        if (Object.keys(this.$route.params).length !== 0) {
            let param = this.$route.params ;
            let page  = param.page ;

            delete param.page ;
            
            this.$router.push({name: page, params: param}) ;
        }
        
    }
};
</script>

