<template>
    <div v-if="show" class="shade-layer">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "WhiteLayer",
    props: ['show']
};
</script>

<style scoped lang="scss">
.shade-layer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 30px;
    right: 10px;
    bottom: 0;
    left: 0;
    z-index: 2000;
    height: 100%;
    overflow: auto;
}
</style>
