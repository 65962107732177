<template>
    <ShadeLayer :show="$store.state.wechatLoginShow" @showEmit="showValue">
        <div class="wechat-login" @click.stop>
            <div class="width-full flex axis-end">
                <Close class="close-icon" @click="shadeClose" />
            </div>
            <div class="flex m-t-10">
                <img src="https://cdn-prd.daozi.com/static/client/login/wechat.png" />
                <span class="text-20 m-l-10">微信扫码登录</span>
            </div>
            <div class="m-t-5">
                <span class="text-describe-14">使用微信扫码关注登录</span>
            </div>
            <div class="m-t-20">
                <!-- <img style="width: 136px;" src="https://cdn-prd.daozi.com/static/images/bottom/wxqr.jpg" /> -->
                <img style="width: 136px;" :src="qrcode" />
            </div>
            <div class="flex m-t-20">
                <div class="flex cursor" @click="loginOther('phone')">
                    <img src="https://cdn-prd.daozi.com/static/client/login/phone.png" />
                    <span class="text-light-14 m-l-5">手机登录</span>
                </div>
                <div class="flex cursor m-l-50" @click="loginOther('qq')">
                    <img src="https://cdn-prd.daozi.com/static/client/login/qq.png" />
                    <span class="text-light-14 m-l-5">QQ登录</span>
                </div>
            </div>
            <div class="flex m-t-20">
                <input class="checkbox-area" type="checkbox" id='policy' checked />
                <label for="policy" class="text-light-14">我已阅读并接受</label>
                <span class="text-policy-14 cursor">《用户协议》</span>
            </div>
            
        </div>
    </ShadeLayer>
</template>

<script>
import { Close } from '@element-plus/icons-vue'
import ShadeLayer from "@/components/layer/ShadeLayer.vue";

export default {
    name: "WechatLogin",
    components: {
        Close,
        ShadeLayer
    },
    data() {
        return {
            wxId: 0,
            qrcode: '',
            wxTiming: null
        }
    },
    mounted() {
        // 挂载读取微信登录状态
        this.$store.commit('getWxLoginStatusVal', this.getWxLoginStatus) ;
    },
    unmounted() {
        this.$store.commit('getWxLoginStatusVal', null) ;

        // 若有定时，关闭，同时设置微信登录全局显示状态
        if (this.wxTiming) {
            clearInterval(this.wxTiming) ;
            this.$store.commit('wechatLoginShowVal', false) ;
        }
    },
    methods: {
        shadeClose() {
            this.$store.commit('wechatLoginShowVal', false) ;
        },
        showValue(data) {
            this.$store.commit('wechatLoginShowVal', data) ;
        },
        // 其他方式登录
        loginOther(param) {
            this.$store.commit('wechatLoginShowVal', false) ;

            switch(param) {
                case 'phone' :
                    this.$store.commit('phoneLoginShowVal', true) ;
                    break ;
                case 'qq' :
                    this.$store.commit('qqLoginShowVal', true) ;
                    break ;
            }
        },
        // 读取微信登录信息
        getWxData() {
            const url = this.$conf.apiUrl + '/client/Login/wechatLogin' ;

            this.$axios.get(url).then((res) => {
                this.wxId = res.data.data.id ;
                this.qrcode = res.data.data.qrcode ;
            }) ;
        },
        // 微信登录状态（定时）
        getWxLoginStatus(val) {
            // 初始
            if (this.qrcode === '') {
                this.getWxData() ;
            }

            // 定时
            if (val) {
                this.wxTiming = setInterval(this.wechatLoginPost, 1000) ;
            } else {
                clearInterval(this.wxTiming) ;
            }
        },
        // 微信登录状态（读取）
        wechatLoginPost() {
            const url = this.$conf.apiUrl + '/client/Login/wechatLoginPost' ;

            this.$axios.post(url, this.$qs.stringify({
                id: this.wxId
            })) .then((res) => {
                if (res.data.code) {
                    this.$setUserInfo(res.data.data) ;
                    this.$store.commit('isLoginVal', true) ;
                    this.$store.commit('wechatLoginShowVal', false) ;

                    // 登录后触发各页面的刷新事件
                    if (this.$store.state.loginEvent) {
                        this.$store.state.loginEvent() ;
                    }
                }
            }) ;
        }

    }
};
</script>

<style scoped lang="scss">
/* 布局 div */
.wechat-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 420px;
    height: 370px;
    padding: 15px;
    margin-bottom: 30px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
}

/* 覆盖 ShadeLayer.vue 样式 */
.shade-layer {
    z-index: 1100;
}

/* 关闭区域 */
.close-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.close-icon:hover {
    color: #FF8051;
}

/* 文字 */
.text-describe-14 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #888888;
}
.text-light-14 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #666666;
}
.text-policy-14 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #ff8e41;
}

/* 复选框样式 */
.checkbox-area{position:absolute;display:none;}
.checkbox-area+label{position:relative;display:block;padding-left:20px;cursor:pointer;}
.checkbox-area+label:hover:before{animation-duration:.4s;animation-fill-mode:both;animation-name:hover-color;}
.checkbox-area+label:before{position:absolute;top:50%;left:0;display:block;box-sizing:border-box;width:14px;height:14px;border:1px solid silver;content:'';transform:translateY(-50%);}
.checkbox-area+label:after{position:absolute;display:none;content:'';}
.checkbox-area:checked+label:before{animation-name:none;}
.checkbox-area:checked+label:after{display:block;}
.checkbox-area+label:before{border-radius:3px;}
.checkbox-area+label:after{top:50%;left:5px;box-sizing:border-box;width:5px;height:10px;border-color:#fff;border-style:solid;border-width:2px;border-top:0;border-left:0;transform:rotate(45deg) translate(-90%,-40%);}
.checkbox-area:checked+label:before{border:#FF8E41;background:#FF8E41;}


</style>
