<template>
    <ShadeLayer :show="show" @showEmit="showValue">
        <div class="phone-login" @click.stop>
            <div class="width-full flex axis-end">
                <Close class="close-icon" @click="shadeClose" />
            </div>
            
            <div class="flex m-t-10">
                <span class="text-20">绑定手机号</span>
            </div>

            <div class="login-form-div m-t-30">
                <input type="text" v-model="phone" class="login-input-phone" placeholder="请输入手机号" />
            </div>
            <div class="login-form-div flex axis-between m-t-20">
                <input type="text" v-model="code" class="login-input-code" placeholder="短信验证码" />
                <span class="login-phone-code" @click="getCode">{{codeText}}</span>
            </div>
            <div class="login-form-div m-t-20">
                <button class="login-button-submit" @click="submit">修改绑定</button>
            </div>

        </div>
    </ShadeLayer>
</template>

<script>
import { ElMessageBox } from 'element-plus'
import { Close } from '@element-plus/icons-vue'
import ShadeLayer from "@/components/layer/ShadeLayer.vue"

export default {
    name: "UserPhone",
    components: {
        Close,
        ShadeLayer
    },
    data() {
        return {
            phone: '',
            code: '',
            codeText: '获取验证码',
            codeTime: 0,
            codeTiming: null,
            submitAllow: true
        }
    },
    props: ['show'],
    created() {
        // 初始化已绑定的手机号
        this.getBindPhone() ;
    },
    unmounted() {
        // 若有定时，关闭
        if (this.codeTime !== 0) {
            clearInterval(this.codeTiming) ;
        }
    },
    methods: {
        shadeClose() {
             this.$emit('showEmit', false) ;
        },
        showValue(data) {
             this.$emit('showEmit', data) ;
        },
        // 60秒倒计时
        codeCountDown() {
            if (this.codeTime < 60) {
                // 显示倒计时
                this.codeText = (60 - this.codeTime) + '秒后再试' ;
                this.codeTime++ ;
            } else {
                // 结束轮询
                clearInterval(this.codeTiming) ;

                // 重置倒计时时间、定时事件、提示语
                this.codeTime = 0 ;
                this.codeTiming = null ;
                this.codeText = '获取验证码' ;
            }
        },
        // 获取验证码
        getCode() {
            // 判断手机号格式
            let phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/ ;

			if (!phoneReg.test(this.phone)) {
                ElMessageBox.alert('手机号格式不正确！', '提示', {confirmButtonText: '确定'}) ;

                return false ;
			}

            // 验证码
            if (this.codeTime === 0) {
                // 启动定时
                this.codeTime++ ;
                this.codeTiming = setInterval(this.codeCountDown, 1000) ;

                // 获取验证码
                const url = this.$conf.apiUrl + '/client/Login/phoneCode' ;

                this.$axios.post(url, this.$qs.stringify({
                    phone: this.phone
                })) .then((res) => {
                    this.detailData = res.data.data ;
                }) ;
            }
        },
        // 获取当前绑定的手机号
        getBindPhone() {
            const url = this.$conf.apiUrl + '/client/Person/bindPhone' ;

            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token,
                detail_id: this.detailId
            })) .then((res) => {
                this.phone = res.data.data ;
            }) ;
        },
        // 登录提交
        submit() {
            // 判断手机号格式
            let phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/ ;

			if (!phoneReg.test(this.phone)) {
                ElMessageBox.alert('手机号格式不正确！', '提示', {confirmButtonText: '确定'}) ;

                return false ;
			}
            
            // 判断验证码非空
            if (this.code === '') {
                ElMessageBox.alert('请填写验证码！', '提示', {confirmButtonText: '确定'}) ;

                return false ;
            }

            // 提交
            if (this.submitAllow) {
                this.submitAllow = false ;

                const url = this.$conf.apiUrl + '/client/Person/bindPhonePost' ;

                this.$axios.post(url, this.$qs.stringify({
                    token: this.$getUserInfo().token,
                    phone: this.phone,
                    code: this.code
                })) .then((res) => {
                    this.submitAllow = true ;
                    
                    if (res.data.code === true && res.data.status === true) {
                        ElMessageBox.alert('绑定成功！', '提示', {confirmButtonText: '确定'}) ;

                        this.$emit('showEmit', false) ;
                    } else {
                        ElMessageBox.alert(res.data.msg, '提示', {confirmButtonText: '确定'}) ;
                    }
                }) ;
            }
        }
    }
};
</script>

<style scoped lang="scss">
/* 布局 div */
.phone-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 420px;
    height: 330px;
    padding: 15px;
    margin-bottom: 30px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
}

/* 覆盖 ShadeLayer.vue 样式 */
.shade-layer {
    z-index: 1100;
}

/* 关闭区域 */
.close-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.close-icon:hover {
    color: #FF8051;
}

/* 表单 */
.login-form-div {
    width: 280px;
}

.login-input-phone {
    width: 280px;
    height: 50px;
    padding-left: 17px;
    box-sizing: border-box;
    border: 1px solid #E2E2E2;
    border-radius: 8px;
    outline: none;
    font-size: 16px;
    background-color: #fff;
}

.login-input-code {
    width: 150px;
    height: 50px;
    padding-left: 17px;
    box-sizing: border-box;
    border: 1px solid #E2E2E2;
    border-radius: 8px;
    outline: none;
    font-size: 16px;
    background-color: #fff;
}
.login-phone-code {
    width: 112px;
    line-height: 49px;
    background: linear-gradient(175deg, #FCB134, #FF8051);
    color: #fff;
    white-space: nowrap;
    text-align: center;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    outline: none;
    cursor: pointer;
}
.login-phone-code:hover {
    opacity: 0.8;
}

.login-button-submit {
    width: 100%;
    height: 40px;
    line-height: 38px;
    box-sizing: border-box;
    background: linear-gradient(175deg, #FCB134, #FF8051);
    color: #fff;
    white-space: nowrap;
    text-align: center;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    outline: none;
    cursor: pointer;
}
.login-button-submit:hover {
    opacity: 0.8;
}

/* 文字 */
.text-light-14 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #666666;
}
.text-policy-14 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #ff8e41;
}


</style>
