<template>
    <ShadeLayer :show="show" @showEmit="showValue">
        <div class="detail" @click.stop>
            <div class="detail-preview">
                <el-scrollbar class="detail-preview-scrollbar" wrap-style="border-radius: 10px;" max-height="90vh">
                    <img class="detail-preview-img" :src="detailData.image" />
                </el-scrollbar>
            </div>
            
            <div class="detail-describe">
                <el-scrollbar height="100%">
                    <div class="detail-describe-div">
                        <div class="detail-describe-title">
                            <img :src="detailIcon" />
                            <span class="m-l-25">{{detailData.title}}</span>
                        </div>

                        <div class="flex m-t-15">
                            <div class="flex m-l-5 cursor" @click="favorite">
                                <img :src="saveIcon" />
                                <span class="m-l-5" :class="[saveActive]">{{saveText}}</span>
                            </div>
                            <div class="flex m-l-20 cursor" @click="vipPayOpen">
                                <img :src="vipImage" />
                                <span class="m-l-5" :class="[vipActive]">会员</span>
                            </div>
                            <div class="flex m-l-20 cursor" @click="share">
                                <img src="https://cdn-prd.daozi.com/static/client/detail/share.png" />
                                <span class="m-l-5">分享</span>
                            </div>
                        </div>

                        <div class="line-between m-t-20"></div>

                        <div class="describe-grid m-t-20">
                            <div class="p-b-10"><span class="text-16">类型：</span></div>
                            <div class="p-b-10"><span class="text-16">VIP模板</span></div>
                            <div class="p-b-10"><span class="text-16">提供者：</span></div>
                            <div class="p-b-10"><span class="text-16">{{detailData.ownership_name}}</span></div>
                            <div class="p-b-10"><span class="text-16">版权说明：</span></div>
                            <div class="p-b-10">
                                <span class="text-16">{{detailData.copyright}}</span>
                                <img class="m-l-5" src="https://cdn-prd.daozi.com/static/images/detail/mark.png" />
                            </div>
                            <div class="p-b-10"><span class="text-16">商用说明：</span></div>
                            <div class="p-b-10"><span class="text-16">{{detailData.business}}</span></div>
                        </div>

                        <div class="flex is-column m-t-10" @click="downloadMaterial">
                            <button class="detail-down-button">立即下载</button>
                        </div>

                        <div class="line-between m-t-20"></div>
                        
                        <div class="describe-grid m-t-20">
                            <div class="p-b-10"><span class="text-16">软件：</span></div>
                            <div class="p-b-10"><span class="text-16">{{detailData.tool}}</span></div>
                            <div class="p-b-10"><span class="text-16">格式：</span></div>
                            <div class="p-b-10"><span class="text-16">{{detailData.format}}</span></div>
                            <div class="p-b-10"><span class="text-16">大小：</span></div>
                            <div class="p-b-10"><span class="text-16">{{detailData.size}}</span></div>
                            <div class="p-b-10"><span class="text-16">页数：</span></div>
                            <div class="p-b-10"><span class="text-16">{{detailData.pages}}</span></div>
                            <div class="p-b-10"><span class="text-16">版权所有：</span></div>
                            <div class="p-b-10"><span class="text-16">{{detailData.ownership_name}}</span></div>
                            <div class="detail-complain flex p-b-10">
                                <span>侵权投诉</span>
                                <img class="m-l-5 m-t-5" src="https://cdn-prd.daozi.com/static/images/detail/check.png" />
                            </div>
                        </div>
                        
                        <div class="line-between m-t-10"></div>

                        <div>
                            <div class="m-t-20"><span class="text-16">相关标签</span></div>
                            <div class="flex is-wrap m-t-10">
                                <div class="flex axis-center p-5" v-for="(item, key) in detailData.tag_list" :key="key" @click="labelToList(item)">
                                    <span class="tab-button">{{item}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="line-between m-t-10"></div>

                        <div class="m-t-20">
                            <div class="flex axis-between">
                                <span class="text-20 text-bold">相似模板</span>
                                <div class="detail-describe-refresh" @click="getRecommend">
                                    <RefreshRight class="refresh-icon" />
                                    <span class="text-14 m-l-5">换一换</span>
                                </div>
                            </div>
                            <div class="detail-recommend">
                                <div class="detail-recommend-item m-t-20" v-for="(item, key) in recommendList" :key="key" @click="recommendTo(item.id)">
                                    <img :src="item.image" />
                                    <span class="text-14 m-t-5">{{item.title}}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </el-scrollbar>
            </div>

            <div class="detail-close" @click="shadeClose">
                <img src="https://cdn-prd.daozi.com/static/client/detail/detail_close.png" />
            </div>
            
        </div>
    </ShadeLayer>

</template>

<script>
import { ElMessageBox } from 'element-plus'
import { RefreshRight } from '@element-plus/icons-vue' ;
import ShadeLayer from "@/components/layer/ShadeLayer.vue";

export default {
    name: "Detail",
    components: {
        RefreshRight,
        ShadeLayer
    },
    data() {
        return {
            detailData: {},
            recommendList: [],
            recommendStart: 0
        }
    },
    props: ['show', 'detailId'],
    computed: {
        // 标题图标
        detailIcon() {
            let path = '' ;

            switch(this.detailData.type) {
                case 1:
                    path = 'https://cdn-prd.daozi.com/static/client/detail/ppt.png' ;
                    break ;
                case 2:
                    path = 'https://cdn-prd.daozi.com/static/client/detail/word.png' ;
                    break ;
                case 3:
                    path = 'https://cdn-prd.daozi.com/static/client/detail/excel.png' ;
                    break ;
                case 4:
                    path = 'https://cdn-prd.daozi.com/static/client/detail/design.png' ;
                    break ;
            }

            return path ;
        },
        // 收藏图标
        saveIcon() {
            if (this.detailData.saved) {
                return 'https://cdn-prd.daozi.com/static/client/detail/star.png' ;
            } else {
                return 'https://cdn-prd.daozi.com/static/client/detail/star_empty.png' ;
            }
        },
        // 当前会员是否激活
        saveActive() {
            let saveActive = '' ;

            if (this.detailData.saved) {
                saveActive = 'save-active' ;
            }

            return saveActive ;
        },
        // 收藏文字
        saveText() {
            let saveText = '收藏' ;

            if (this.detailData.saved) {
                saveText = '已收藏' ;
            }

            return saveText ;
        },
        // 当前会员显示的图片
        vipImage() {
            let vipImage = 'https://cdn-prd.daozi.com/static/client/detail/vip_empty.png' ;

            if (this.$store.state.isLogin) {
                if (this.$getUserInfo().vip_info.vip_type > 0) {
                    vipImage = 'https://cdn-prd.daozi.com/static/client/detail/vip.png' ;
                }
            }

            return vipImage ;
        },
        // 当前会员是否激活
        vipActive() {
            let vipActive = '' ;

            if (this.$store.state.isLogin) {
                if (this.$getUserInfo().vip_info.vip_type > 0) {
                    vipActive = 'vip-active' ;
                }
            }

            return vipActive ;
        }
    },
    watch: {
        // 监听显示状态，并判断是否读取详情
        show(newVal) {
            if (newVal === true) {
                this.getDetail() ;
                this.getRecommend() ;
            }
        }
    },
    methods: {
        shadeClose() {
            this.$emit('showEmit', false) ;
        },
        showValue(data) {
            this.$emit('showEmit', data) ;
        },
        // 读取详情
        getDetail() {
            const url = this.$conf.apiUrl + '/client/Material/detail' ;

            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token,
                detail_id: this.detailId
            })) .then((res) => {
                this.detailData = res.data.data ;
            }) ;
        },
        // 收藏
        favorite() {
            if (this.$store.state.isLogin) {
                const url = this.$conf.apiUrl + '/client/Person/favorite' ;
                
                this.$axios.post(url, this.$qs.stringify({
                    token: this.$getUserInfo().token,
                    detail_id: this.detailId
                })) .then((res) => {
                    if (res.data.code) {
                        this.detailData.saved = !this.detailData.saved ;
                        this.$emit('favoriteEmit', this.detailData.saved) ;
                    }
                }) ;
            } else {
                // 未登录，需要先登录
                this.$store.commit('wechatLoginShowVal', true) ;
            }
        },
        // 分享
        share() {
            let url = this.$conf.apiUrl + '/detail/' + this.detailId ;
            navigator.clipboard.writeText(url) ;

            ElMessageBox.alert('链接复制成功，请在浏览器中访问该链接！', '提示', {confirmButtonText: '确定'}) ;
        },
        // 打开VIP购买页面
        vipPayOpen() {
            if (this.$store.state.isLogin) {
                this.$store.commit('vipPayShowVal', true) ;
            } else {
                this.$store.commit('wechatLoginShowVal', true) ;
            }
        },
        // 标签搜索
        labelToList(label) {
            this.$router.push({name: 'empty', params: {page: 'list', search: label}}) ;
        },
        // 推荐列表
        getRecommend() {
            const url = this.$conf.apiUrl + '/client/Material/detailRecommend' ;

            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token,
                detail_id: this.detailId,
                start: this.recommendStart
            })) .then((res) => {
                this.recommendList = res.data.data ;
                this.recommendStart = this.recommendStart + 1 ;
            }) ;
        },
        // 跳转到推荐
        recommendTo(recommendId) {
            this.$emit('recommendEmit', recommendId) ;
        },
        // 下载开始
        downloadMaterial() {
            // 是否登录
            if (this.$store.state.isLogin) {
                if (this.$getUserInfo().vip_info.vip_type != 0) {
                    // 获取下载地址
                    const url = this.$conf.apiUrl + '/client/Person/vipDownload' ;

                    this.$axios.post(url, this.$qs.stringify({
                        token: this.$getUserInfo().token,
                        detail_id: this.detailId
                    })) .then((res) => {
                        if (res.data.code) {
                            let material = {} ;
                            
                            material.id = this.detailId ;
                            material.type = this.detailData.type ;
                            material.title = this.detailData.title ;
                            material.download = res.data.data.download ;
                            material.filename = res.data.data.filename ;

                            if (this.$store.state.downloadStart) {
                                this.$store.state.downloadStart(material) ;
                            }
                        } else {
                            ElMessageBox.alert(res.data.msg, '提示', {confirmButtonText: '确定'}) ;
                        }
                    }) ;
                } else {
                    // 无会员，跳出会员购买页
                    this.$store.commit('vipPayShowVal', true) ;
                }
            } else {
                // 未登录，跳转到登录界面
                this.$store.commit('wechatLoginShowVal', true) ;
            }
        }
    }
};
</script>

<style scoped lang="scss">
/* 布局 div */
.detail {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 1050px;
    height: calc(90vh + 20px);
    margin-bottom: 30px;
}

/* 预览图 */
.detail-preview {
    width: 650px;
    max-height: calc(90vh + 20px);
    padding: 10px 0px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
}
.detail-preview-scrollbar {
    padding: 0px 10px;
}
.detail-preview-img {
    width: 100%;
    border-radius: 10px;
}

/* 详细信息 */
.detail-describe {
    width: 330px;
    height: 95%;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
}
.detail-describe-div {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
.line-between {
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
}

.detail-describe-title {
    position: relative;
}
.detail-describe-title>img {
    position: absolute;
    top: 4px;
    width: 20px;
}
.detail-describe-title>span {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.save-active {
    color: #FF8E41;
}
.vip-active {
    color: #FF8E41;
}

.describe-grid {
	display: grid;
	grid-template-columns: 1fr 2fr;
}

.detail-down-button {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: url(https://cdn-prd.daozi.com/static/client/detail/detail_down_button.png) no-repeat;
    background-size: 100% 100%;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    font-size: 18px;
    outline: none;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.detail-down-button:hover {
    opacity: 0.9;
}

.detail-complain {
    cursor: pointer;
}
.detail-complain>span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #6795FB;
}

.tab-button {
    padding: 3px 10px;
    font-size: 14px;
    color: #FF8E41;
    background-color: #fefaf7;
    border: 1px solid #f5d7c4;
    border-radius: 20px;
    cursor: pointer;
}
.tab-button:hover {
    color: #ffffff;
    background-color: #FF8E41;
}

.detail-describe-refresh {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.refresh-icon {
    width: 16px;
    height: 16px;
    color: #333333;
}
.detail-describe-refresh:hover>.refresh-icon {
    color: #FF8E41;
}
.detail-describe-refresh:hover>span {
    color: #FF8E41;
}

.detail-recommend {
    display: grid;
	grid-template-columns: 130px 130px;
    justify-content: space-between;
}
.detail-recommend-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.detail-recommend-item>img {
    width: 130px;
    border-radius: 5px;
}

/* 关闭按钮 */
.detail-close {
    cursor: pointer;
}


</style>
