import axios from "axios";
import qs from 'qs';
import conf from "@/common/conf.js";

// 读取登录后的用户信息
const getUserInfo = () => {
    let userInfo = window.localStorage.getItem('userInfo') ;

    try {
        userInfo = JSON.parse(userInfo) ;
    } catch (err) {
        userInfo = '' ;
    }
    
    if (userInfo) {
        let now = Date.parse(new Date()) / 1000 ;

        if ((userInfo.expire_time - now) < 600) {
            userInfo = '' ;
        }
    }
    
    return userInfo ? userInfo : {'token': ''} ;
}

// 存储登录后的用户信息
const setUserInfo = (userInfo) => {
    window.localStorage.setItem('userInfo', JSON.stringify(userInfo)) ;
}

export default{
    install: (app) => {
        app.config.globalProperties['$axios'] = axios ;
        app.config.globalProperties['$qs'] = qs ;
        app.config.globalProperties['$conf'] = conf ;
        app.config.globalProperties['$getUserInfo'] = getUserInfo ;
        app.config.globalProperties['$setUserInfo'] = setUserInfo ;
    }
}
