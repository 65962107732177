<template>
    <div class="width-full p-b-100">
        <!-- 导航 banner -->
        <div class="index-banner">
            <div>
                <div class="nav-search">
                    <div class="nav-list">
                        <div class="nav-list-title p-b-16">
                            <span>{{searchTypeShow}}</span>
                            <ArrowDown class="nav-list-icon m-l-3 m-t-1" />
                            <div class="nav-search-line m-l-20 m-r-20"></div>
                        </div>
                        <div class="nav-list-div m-l-m-20">
                            <span @click="setSearchType(0)">全部</span>
                            <span @click="setSearchType(1)">PPT模板</span>
                            <span @click="setSearchType(2)">Word模板</span>
                            <span @click="setSearchType(3)">Excel模板</span>
                            <span @click="setSearchType(4)">广告设计</span>
                        </div>
                    </div>

                    <div class="nav-search-input">
                        <input type="text" v-model="searchKey" @keyup.enter="searchTo" placeholder="搜索优质ppt和办公模板">
                    </div>

                    <div class="nav-search-button" @click="searchTo">
                        <img src="https://cdn-prd.daozi.com/static/imagel/search_icon.png" />
                    </div>
                </div>

                <div class="flex p-t-10 p-l-5">
                    <span class="index-hot-title">热门搜索： </span>
                    <span class="index-hot-search m-l-10" @click="hotSearchTo('工作总结')">工作总结</span>
                    <span class="index-hot-search m-l-10" @click="hotSearchTo('个人简历')">个人简历</span>
                    <span class="index-hot-search m-l-10" @click="hotSearchTo('财务报表')">财务报表</span>
                    <span class="index-hot-search m-l-10" @click="hotSearchTo('课件')">课件</span>
                    <span class="index-hot-search m-l-10" @click="hotSearchTo('论文')">论文</span>
                </div>
            </div>
            
        </div>

        <!-- 分类列表 -->
        <div class="container m-t-50">
            <CategoryList />
        </div>
        
        <!-- 专题推荐 -->
        <div class="container m-t-50">
            <div>
                <span class="text-24">推荐</span>
            </div>
            <div class="index-theme-list width-full flex axis-between m-t-20">
                <img :src="item.image" v-for="(item, key) in themeList" :key="key"
                    @click="$router.push(item.url)" />
            </div>
        </div>

        <!-- PPT 模板列表 -->
        <div class="container flex is-column m-t-50">
            <div class="width-full flex axis-between">
                <span class="text-24">PPT模板</span>
                <div class="flex cursor" @click="$router.push({name: 'list', params: {type: 1, scene: 0, purpose: 0}})">
                    <span class="text-16 m-r-5">更多</span>
                    <img src="https://cdn-prd.daozi.com/static/images/index/more_down.png" />
                </div>
            </div>
            <div class="width-full flex is-wrap axis-between">
                <div class="list-two-row m-t-30" v-for="(item, key) in fineList[0]" :key="key">
                    <ListMaterial :material="item" @recommendEmit="recommendMaterial" />
                </div>
            </div>
        </div>

        <!-- Word文档 -->
        <div class="container flex is-column m-t-50">
            <div class="width-full flex axis-between">
                <span class="text-24">Word文档</span>
                <div class="flex cursor" @click="$router.push({name: 'list', params: {type: 2, scene: 0, purpose: 0}})">
                    <span class="text-16 m-r-5">更多</span>
                    <img src="https://cdn-prd.daozi.com/static/images/index/more_down.png" />
                </div>
            </div>
            <div class="width-full flex is-wrap axis-between">
                <div class="list-two-row m-t-30" v-for="(item, key) in fineList[1]" :key="key">
                    <ListMaterial :material="item" @recommendEmit="recommendMaterial" />
                </div>
            </div>
        </div>

        <!-- Excel表格 -->
        <div class="container flex is-column m-t-50">
            <div class="width-full flex axis-between">
                <span class="text-24">Excel表格</span>
                <div class="flex cursor" @click="$router.push({name: 'list', params: {type: 3, scene: 0, purpose: 0}})">
                    <span class="text-16 m-r-5">更多</span>
                    <img src="https://cdn-prd.daozi.com/static/images/index/more_down.png" />
                </div>
            </div>
            <div class="width-full flex is-wrap axis-between">
                <div class="list-two-row m-t-30" v-for="(item, key) in fineList[2]" :key="key">
                    <ListMaterial :material="item" @recommendEmit="recommendMaterial" />
                </div>
            </div>
        </div>

        <!-- 热门海报 -->
        <div class="container flex is-column m-t-50">
            <div class="width-full flex axis-between">
                <span class="text-24">热门海报</span>
                <div class="flex cursor" @click="$router.push({name: 'list', params: {type: 4, scene: 24, purpose: 0}})">
                    <span class="text-16 m-r-5">更多</span>
                    <img src="https://cdn-prd.daozi.com/static/images/index/more_down.png" />
                </div>
            </div>
            <div class="width-full flex is-wrap axis-between">
                <div class="list-one-row m-t-30" v-for="(item, key) in fineList[3]" :key="key">
                    <ListMaterial :material="item" @recommendEmit="recommendMaterial" />
                </div>
            </div>
        </div>

        <!-- 设计元素 -->
        <div class="container flex is-column m-t-50">
            <div class="width-full flex axis-between">
                <span class="text-24">设计元素</span>
                <div class="flex cursor" @click="$router.push({name: 'list', params: {type: 4, scene: 25, purpose: 106}})">
                    <span class="text-16 m-r-5">更多</span>
                    <img src="https://cdn-prd.daozi.com/static/images/index/more_down.png" />
                </div>
            </div>
            <div class="width-full flex is-wrap axis-between">
                <div class="list-one-row m-t-30" v-for="(item, key) in fineList[4]" :key="key">
                    <ListMaterial :material="item" @recommendEmit="recommendMaterial" />
                </div>
            </div>
        </div>
    </div>

    <DetailSingle :show="recommendShow" :detailId="recommendId" @showEmit="recommendValue" @favoriteEmit="favoriteValue" @recommendEmit="recommendSingle" />
</template>

<script>
import { ArrowDown } from '@element-plus/icons-vue'
import CategoryList from "@/components/category/CategoryList.vue";
import ListMaterial from "@/components/material/ListMaterial.vue";
import DetailSingle from "@/components/material/DetailSingle.vue";

export default {
    name: "Index",
    components: {
        ArrowDown,
        CategoryList,
        ListMaterial,
        DetailSingle
    },
    data() {
        return {
            searchKey: '',
            searchType: 0,
            searchTypeShow: '全部',
            themeList: [],
            fineList: [],
            recommendShow: false,
            recommendId: 0
        };
    },
    created() {
        // 专题推荐列表
        this.getThemeList() ;

        // 精品素材列表
        this.getFineList() ;
    },
    mounted() {
        // 挂载登录、退出后回调事件
        this.$store.commit('loginEventVal', this.loginEvent) ;
    },
    unmounted() {
        this.$store.commit('loginEventVal', null) ;
    },
    methods: {
        // 搜索
        searchTo() {
            this.$router.push({name: 'empty', params: {page: 'list', type: this.searchType, search: this.searchKey}}) ;
        },
        // 热门搜索
        hotSearchTo(search) {
            this.searchKey = search ;
            this.searchTo() ;
        },
        // 转换显示的搜索类型
        setSearchType(type) {
            this.searchType = type ;

            switch (type) {
                case 0:
                    this.searchTypeShow = '全部' ;
                    break ;
                case 1:
                    this.searchTypeShow = 'PPT模板' ;
                    break ;
                case 2:
                    this.searchTypeShow = 'Word模板' ;
                    break ;
                case 3:
                    this.searchTypeShow = 'Excel模板' ;
                    break ;
                case 4:
                    this.searchTypeShow = '广告设计' ;
                    break ;
                default:
                    this.searchTypeShow = '全部' ;
                    break ;
            }
        },
        // 专题推荐列表
        getThemeList() {
            const url = this.$conf.apiUrl + '/client/Conf/indexTheme' ;

            this.$axios.get(url).then((res) => {
                this.themeList = res.data.data ;
            }) ;
        },
        // 精品素材列表
        getFineList() {
            const url = this.$conf.apiUrl + '/client/Material/fineList' ;
            
            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token
            })) .then((res) => {
                this.fineList = res.data.data ;
            }) ;
        },
        // 登录、退出后触发的事件
        loginEvent() {
            const url = this.$conf.apiUrl + '/client/Material/savedRefreshIndex' ;
            
            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token,
                list: this.fineList
            })) .then((res) => {
                this.fineList = res.data.data ;
            }) ;
        },
        // 推荐变更（material）
        recommendMaterial(recommendId) {
            this.recommendId = recommendId ;
        },
        // 推荐变更（single）
        recommendSingle(recommendId) {
            this.recommendId = recommendId ;
            this.recommendShow = false ;
        },
        // 推荐界面显示状态
        recommendValue(data) {
            this.recommendShow = data ;
        },
        // 推荐详情页收藏状态变更触发事件
        favoriteValue(recommendId) {
            for (let val of this.fineList) {
                for (let v of val) {
                    if (v.id === recommendId) {
                        v.saved = !v.saved ;
                    }
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
/* 导航 banner */
// 最外层 div
.index-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 390px;
    background: url(https://cdn-prd.daozi.com/static/client/index/banner.png) center no-repeat;
}

// 分类区域
.nav-list {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: 28px;
    cursor: pointer;
}
.nav-list-title {
    display: flex;
    align-items: center;
}
.nav-list-title>span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #333333;
}
.nav-list-icon {
    width: 16px;
    height: 16px;
}
.nav-list-div {
    display: none;
    width: 111px;
    padding: 10px 20px;
    box-sizing: border-box;
    z-index: 1;
    background-color: #F9F9F9;
    color: #333333;
    border-radius: 6px;
    box-shadow: 0px 1px 9px 0px rgba(5, 5, 5, 0.22);
}
.nav-list:hover>.nav-list-div {
    display: flex;
    flex-direction: column;
}
.nav-list>.nav-list-div:hover {
    display: flex;
    flex-direction: column;
}
.nav-list-div>span {
    line-height: 40px;
    font-size: 15px;
}
.nav-list-div>span:hover {
    color: #ff8e41;
}

.m-l-m-20 {
    margin-left: -20px;
}

// 搜索区域
.nav-search {
    display: flex;
    align-items: center;
    padding-left: 20px;
    width: 850px;
    height: 56px;
    box-sizing: border-box;
    font-size: 16px;
    color: #666666;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.12);
}

.nav-search-line {
    width: 1px;
    height: 28px;
    background: #C1C1C1;
}

.nav-search-input {
    flex: 1;
}
.nav-search-input>input {
    width: 100%;
    height: 40px;
    padding-left: 5px;
    font-size: 16px;
    border: none;
    outline: 0px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0);
}
.nav-search-input>input::placeholder {
    color: #666666;
}

.nav-search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 56px;
    background-color: #ff8e41;
    border-radius: 0px 6px 6px 0px;
    cursor: pointer;
}
.nav-search-button:hover {
    opacity: 0.9;
}
.nav-search-button>img {
    width: 25px;
    height: 25px;
}

// 热门搜索
.index-hot-title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #ffffff;
}
.index-hot-search {
    padding: 5px 12px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    cursor: pointer;
}
.index-hot-search:hover {
    color: #ff8e41;
}

/* 专题推荐 */
.index-theme-list>img {
    width: 343px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.12);
    font-size: 10px;
    cursor: pointer;
}
@media only screen and (max-width: 1850px) {
    .index-theme-list>img {
        width: 290px;
    }
}
@media only screen and (max-width: 1450px) {
    .index-theme-list>img {
        width: 226px;
    }
}

/* 精品列表 */
@media only screen and (max-width: 1450px) {
    .list-one-row:nth-last-child(1) {
        display: none;
    }
    .list-two-row:nth-last-child(1) {
        display: none;
    }
    .list-two-row:nth-last-child(2) {
        display: none;
    }
}

</style>
