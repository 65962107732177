import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
import Index from '../views/Index.vue'
import List from '../views/List.vue'
import Theme from '../views/Theme.vue'
import User from '../views/User.vue'
import Empty from '../views/Empty.vue'


const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: {
      keepAlive: 'index'
    }
  },
  {
    path: '/list',
    name: 'list',
    component: List,
    meta: {
      keepAlive: 'main'
    }
  },
  {
    path: '/theme',
    name: 'theme',
    component: Theme,
    meta: {
      keepAlive: 'main'
    }
  },
  {
    path: '/user',
    name: 'user',
    component: User,
    meta: {
      keepAlive: 'main'
    }
  },
  {
    path: '/empty',
    name: 'empty',
    component: Empty,
    meta: {
      keepAlive: 'none'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.afterEach(() => {
  // 解决跳转页面后，不在顶部问题
  store.state.setScrollTop(0) ;
})


export default router
