<template>
    <div v-if="show" class="shade-layer" @click="close()">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ShadeLayer",
    props: ['show'],
    methods: {
        close() {
            this.$emit('showEmit', false) ;
        },
    }
};
</script>

<style scoped lang="scss">
.shade-layer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 30px;
    right: 10px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: #00000080;
    overflow: auto;
}
</style>
