<template>
    <div class="flex">
        <div class="category-list">
            <div class="flex">
                <div class="flex m-10 category-list-item" @click="$router.push({name: 'list', params: {type: 1, scene: 0, purpose: 0}})">
                    <img src="https://cdn-prd.daozi.com/static/client/index/ppt.png" />
                    <span class="text-16 text-hover m-l-5">演示</span>
                </div>
                <div class="flex m-10 category-list-item" @click="$router.push({name: 'list', params: {type: 3, scene: 0, purpose: 0}})">
                    <img src="https://cdn-prd.daozi.com/static/client/index/excel.png" />
                    <span class="text-16 text-hover m-l-5">表格</span>
                </div>
            </div>
            <div class="flex">
                <div class="flex m-10 category-list-item" @click="$router.push({name: 'list', params: {type: 2, scene: 0, purpose: 0}})">
                    <img src="https://cdn-prd.daozi.com/static/client/index/word.png" />
                    <span class="text-16 text-hover m-l-5">文字</span>
                </div>
                <div class="flex m-10 category-list-item" @click="$router.push({name: 'list', params: {type: 4, scene: 25, purpose: 0}})">
                    <img src="https://cdn-prd.daozi.com/static/client/index/element.png" />
                    <span class="text-16 text-hover m-l-5">元素</span>
                </div>
            </div>
            <div class="flex">
                <div class="flex m-10 category-list-item" @click="$router.push({name: 'list', params: {type: 4, scene: 24, purpose: 0}})">
                    <img src="https://cdn-prd.daozi.com/static/client/index/poster.png" />
                    <span class="text-16 text-hover m-l-5">海报</span>
                </div>
                <div class="flex m-10 category-list-item" @click="$router.push({name: 'list', params: {type: 4, scene: 25, purpose: 106}})">
                    <img src="https://cdn-prd.daozi.com/static/client/index/icon.png" />
                    <span class="text-16 text-hover m-l-5">图标</span>
                </div>
            </div>
        </div>

        <div class="key-all flex axis-between flex-one m-l-50">
            <div class="key-list flex is-wrap">
                <span class="p-t-10 p-b-10" v-for="(item, key) in indexKeyList" :key="key" @click="$router.push(item.url)">
                    {{item.name}}
                </span>
            </div>
            
            <div class="key-line-between m-t-1"></div>

            <div class="category-all flex is-column m-l-20 m-r-20" @click="allOpen">
                <img src="https://cdn-prd.daozi.com/static/client/index/all.png" />
                <span class="text-16 m-t-5">全部</span>
            </div>
        </div>
    </div>

    <CategoryAll :show="allShow" @showEmit="allValue" />
</template>

<script>
import CategoryAll from "@/components/category/CategoryAll.vue";

export default {
    name: "CategoryList",
    components: {
        CategoryAll
    },
    data() {
        return {
            indexKeyList: [],
            allShow: false
        };
    },
    created() {
        this.getKeyList() ;
    },
    methods: {
        allOpen() {
            this.allShow = true ;
        },
        allClose() {
            this.allShow = false ;
        },
        allValue(data) {
            this.allShow = data ;
        },
        // 获取首页关键词列表
        getKeyList() {
            const url = this.$conf.apiUrl + '/client/Conf/indexKeyList' ;

            this.$axios.get(url).then((res) => {
                this.indexKeyList = res.data.data ;
            }) ;
        }

    },
};
</script>

<style scoped lang="scss">
/* 分类列表 */
.category-list {
    padding: 10px;
    background: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.12);
}
.category-list-item:hover {
    cursor: pointer;
}
.category-list-item:hover>span {
    color: #ff8e41;
}

/* 关键词和全部分类外框 */
.key-all {
    padding: 10px 20px;
    background: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.12);
}

/* 关键词列表 */
.key-list {
    width: 1130px;
    height: 123px;
    overflow: hidden;
}
@media only screen and (max-width: 1850px) {
    .key-list {
        width: 900px;
    }
}
@media only screen and (max-width: 1450px) {
    .key-list {
        width: 600px;
    }
}

.key-list>span {
    width: 75px;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #333333;
}
.key-list>span:hover {
    color: #ff8e41;
    cursor: pointer;
}

.key-line-between {
    width: 1px;
    height: 95px;
    background: #dddddd;
}

/* 全部分类 */
.category-all:hover {
    cursor: pointer;
}
.category-all:hover>span {
    color: #ff8e41;
}

</style>
