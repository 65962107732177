<template>
    <div class="width-full p-b-100">
        <div class="container m-t-30">
            <!-- 面包屑 -->
            <div class="flex m-t-20">
                <span class="text-light-14 text-light-hover" @click="$router.push('/')">首页</span>
                <span class="text-light-14 m-l-5 m-r-5">></span>
                <span class="text-light-14">列表</span>
            </div>

            <!-- 分类导航 -->
            <div class="list-nav m-t-20">
                <div class="list-nav-banner">
                    <div></div>
                </div>
                <div class="list-nav-main">
                    <CategoryNav :category="category" @categoryEmit="watchCategory" />
                </div>
            </div>
            
            <!-- 素材列表 -->
            <div class="m-t-30">
                <div class="list-div-order">
                    <span :class="[orderActive(0)]" @click="watchOrder(0)">综合排序</span>
                    <span class="m-l-20" :class="[orderActive(1)]" @click="watchOrder(1)">最近更新</span>
                    <span class="m-l-20" :class="[orderActive(2)]" @click="watchOrder(2)">火热下载</span>
                </div>
                <div class="list-div-main m-t-30">
                    <div :class="[heightClass(item)]" v-for="(item, key) in listData" :key="key">
                        <ListMaterial :material="item" @recommendEmit="recommendMaterial" />
                    </div>
                </div>
            </div>

            <!-- 触底区域 -->
            <div ref="bottomLine" class="bottom-line width-full flex axis-center m-t-30">
                <span>------------------------------ {{bottomLine}} ------------------------------</span>
            </div>
        </div>

        <!-- 返回顶部 -->
        <div class="to-top" v-if="toTopShow" @click="toTop()">
            <ArrowUpBold class="to-top-icon" />
        </div>
    </div>

    <DetailSingle :show="recommendShow" :detailId="recommendId" @showEmit="recommendValue" @favoriteEmit="favoriteValue" @recommendEmit="recommendSingle" />
</template>

<script>
import { ArrowUpBold } from '@element-plus/icons-vue' ;
import CategoryNav from "@/components/category/CategoryNav.vue" ;
import ListMaterial from "@/components/material/ListMaterial.vue" ;
import DetailSingle from "@/components/material/DetailSingle.vue";

export default {
    name: "List",
    components: {
        ArrowUpBold,
        CategoryNav,
        ListMaterial,
        DetailSingle
    },
    data() {
        return {
            listData: [],
            category: {type: 1, scene: 0, purpose: 0},
            order: 0,
            page: 1,
            search: '',
            bottomLine: '我是有底线的',
            loadMoreAllow: true,
            toTopShow: false,
            recommendShow: false,
            recommendId: 0
        };
    },
    created() {
        // 获取参数
        if (this.$route.params.type) {
            this.category.type = parseInt(this.$route.params.type) ;
        }
        if (this.$route.params.scene) {
            this.category.scene = parseInt(this.$route.params.scene) ;
        }
        if (this.$route.params.purpose) {
            this.category.purpose = parseInt(this.$route.params.purpose) ;
        }
        if (this.$route.params.search) {
            this.search = this.$route.params.search ;
        }

        // 素材列表
        this.getListData() ;
    },
    mounted() {
        // 挂载触底事件
        this.$store.commit('bottomLineLoadVal', this.loadMore) ;

        // 挂载登录、退出后回调事件
        this.$store.commit('loginEventVal', this.loginEvent) ;
    },
    unmounted() {
        this.$store.commit('bottomLineLoadVal', null) ;
        this.$store.commit('loginEventVal', null) ;
    },
    computed: {
        // 不同分类设置的高度不同
        heightClass() {
            return (material) => {
                let resClass = 'waterfall-common' ;

                if (material.type === 2) {
                    resClass = 'waterfall-high' ;
                }
                if (material.type === 4 && material.scene === 24) {
                    resClass = 'waterfall-high' ;
                }
                if (material.type === 4 && material.scene === 19) {
                    resClass = 'waterfall-inbetweening' ;
                }

                return resClass ;
            }
        },
        // 当前激活的排序
        orderActive() {
            return (order) => {
                if (order === this.order) {
                    return 'active' ;
                } else {
                    return '' ;
                }
            }
        }
    },
    methods: {
        // 分类变更
        watchCategory() {
            this.page = 1 ;
            this.listData = [] ;
            this.getListData() ;
        },
        // 排序变更
        watchOrder(order) {
            this.order = order ;
            this.page = 1 ;
            this.listData = [] ;
            this.getListData() ;
        },
        // 触底加载更多
        loadMore(scrollTop) {
            // 判断是否读取数据
            if (this.loadMoreAllow && this.page < 10) {
                let bottomGap = this.$refs.bottomLine.getBoundingClientRect().top - document.body.clientHeight ;
                
                if (bottomGap < 300) {
                    this.loadMoreAllow = false ;
                    this.page++ ;
                    this.getListData() ;
                }
            }
            
            // 判断是否显示返回顶部
            if (this.toTopShow === false && scrollTop > 1000) {
                this.toTopShow = true ;
            }
            if (this.toTopShow === true && scrollTop < 1000) {
                this.toTopShow = false ;
            }
        },
        // 返回顶部
        toTop() {
            this.$store.state.setScrollTop(0) ;
        },
        // 登录、退出后触发的事件
        loginEvent() {
            const url = this.$conf.apiUrl + '/client/Material/savedRefresh' ;
            
            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token,
                list: this.listData
            })) .then((res) => {
                this.listData = res.data.data ;
            }) ;
        },
        // 读取素材列表
        getListData() {
            const url = this.$conf.apiUrl + '/client/Material/categoryList' ;

            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token,
                type_id: this.category.type,
                scene_id: this.category.scene,
                purpose_id: this.category.purpose,
                order: this.order,
                page: this.page,
                search: this.search
            })) .then((res) => {
                this.listData.push.apply(this.listData, res.data.data) ;
                this.loadMoreAllow = true ;
            }) ;
        },
        // 推荐变更（material）
        recommendMaterial(recommendId) {
            this.recommendId = recommendId ;
        },
        // 推荐变更（single）
        recommendSingle(recommendId) {
            this.recommendId = recommendId ;
            this.recommendShow = false ;
        },
        // 推荐界面显示状态
        recommendValue(data) {
            this.recommendShow = data ;
        },
        // 推荐详情页收藏状态变更触发事件
        favoriteValue(recommendId) {
            for (let val of this.listData) {
                if (val.id === recommendId) {
                    val.saved = !val.saved ;
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
/* 面包屑 */
.text-light-14 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #666666;
}
.text-light-hover:hover {
    color: #ff8e41;
    cursor: pointer;
}

/* 分类导航 */
.list-nav {
    border-radius: 10px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.12);
}
.list-nav-banner {
    padding: 15px 15px 0px 15px;
}
.list-nav-banner>div {
    width: 100%;
    height: 176px;
    border-radius: 10px 10px 0px 0px;
    background: url(https://cdn-prd.daozi.com/static/images/list/list_header.png) center no-repeat;
}
.list-nav-main {
    padding: 20px 35px 20px 35px;
    border-radius: 10px;
}

/* 素材列表 */
// 排序的
.list-div-order>span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #333333;
    cursor: pointer;
}
.list-div-order>span:hover {
    color: #666666;
}
.list-div-order>span.active {
    color: #ff8e41;
}

// 列表的
.list-div-main {
    display: grid;
    grid-template-columns: repeat(auto-fill, 277px);
    grid-auto-flow: row dense;
    grid-auto-rows: 27px;
    justify-content: space-between;
}

@media only screen and (max-width: 1850px) {
    .list-div-main {
        grid-template-columns: repeat(auto-fill, 235px);
        grid-auto-rows: 22px;
    }
}
@media only screen and (max-width: 1450px) {
    .list-div-main {
        grid-template-columns: repeat(auto-fill, 227px);
        grid-auto-rows: 21px;
    }
}

.waterfall-common {
    grid-row: auto / span 14;
}
.waterfall-high {
    grid-row: auto / span 17;
}
.waterfall-inbetweening {
    grid-row: auto / span 16;
}
@media only screen and (max-width: 1850px) {
    .waterfall-common {
        grid-row: auto / span 15;
    }
    .waterfall-high {
        grid-row: auto / span 18;
    }
    .waterfall-inbetweening {
        grid-row: auto / span 17;
    }
}
@media only screen and (max-width: 1450px) {
    .waterfall-common {
        grid-row: auto / span 15;
    }
    .waterfall-high {
        grid-row: auto / span 18;
    }
    .waterfall-inbetweening {
        grid-row: auto / span 17;
    }
}

/* 触底区域 */
.bottom-line>span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #666666;
}

/* 返回顶部 */
.to-top {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 100px;
    right: 25px;
    width: 35px;
    height: 35px;
    z-index: 100;
    border-radius: 35px;
    background-color: #f6f7f9;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}
.to-top-icon {
    width: 20px;
    height: 20px;
    color: #666666;
}
.to-top:hover {
    background-color: #ff8e41;
}
.to-top:hover>.to-top-icon {
    color: #ffffff;
}

</style>
