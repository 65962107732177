<template>
    <div class="m-20" v-if="!emptyHint">
        <div class="list-header">
            <span>会员类型</span>
            <span>金额</span>
            <span>获取时间</span>
            <span>到期时间</span>
        </div>

        <div class="list-line m-t-20 m-b-20"></div>

        <div class="list-content" v-for="(item, key) in listData" :key="key">
            <span>{{item.vip_type}}</span>
            <span>{{item.price}}</span>
            <span>{{item.created_time}}</span>
            <span>{{item.end_time}}</span>
        </div>
    </div>
    <div class="no-res-tip" v-if="emptyHint">
        <img src="https://cdn-prd.daozi.com/static/images/vip/no_vip.png" />
        <span>
            您还尚未开通任何VIP，加入VIP立享特权！
        </span>
    </div>
</template>

<script>
export default {
    name: "UserVip",
    data() {
        return {
            listData: [],
            emptyHint: false
        };
    },
    created() {
        this.getListData() ;
    },
    methods: {
        // 读取购买记录
        getListData() {
            const url = this.$conf.apiUrl + '/client/Person/orderList' ;

            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token
            })) .then((res) => {
                this.listData = res.data.data ;

                if (this.listData.length === 0) {
                    this.emptyHint = true ;
                }
            }) ;
        }
    }
    
};
</script>

<style scoped lang="scss">
/* 列表头部 */
.list-header {
    display: flex;
    align-items: center;
}
.list-header>span {
    width: 25%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
}

/* 列表分隔线 */
.list-line {
    width: 100%;
    height: 1px;
    background: #FF8E41;
}

/* 列表内容 */
.list-content {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.list-content>span {
    width: 25%;
    text-align: center;
    font-size: 16px;
    color: #666666;
}

/* 没有支付记录时的提示 */
.no-res-tip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
    text-align: center;
    font-size: 26px;
    font-family: Microsoft YaHei;
    color: #666666;
}

</style>
