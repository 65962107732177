<template>
    <div class="client-nav flex axis-between">
        <div class="client-item">
            <img class="m-l-15" src="https://cdn-prd.daozi.com/static/client/nav/favicon.ico" />
            <span class="m-l-5 text-14">稻子办公</span>
        </div>
        <div class="client-item">
            <div class="client-item-div" @click="minWindow">
                <Minus class="client-item-icon" />
            </div>
            <div class="client-item-div" @click="maxWindow">
                <img class="client-item-icon min" src="https://cdn-prd.daozi.com/static/client/nav/max.svg" />
            </div>
            <div class="client-item-div close m-r-5" @click="closeWindow">
                <Close class="client-item-icon" />
            </div>
        </div>
    </div>

    <DownloadList />
    <WechatLogin />
    <PhoneLogin />
    <QqLogin />
    <VipPay />
</template>

<script>
import ipc from "@/common/ipc.js";

import { Minus, Close } from '@element-plus/icons-vue'
import DownloadList from "@/components/download/DownloadList.vue";
import WechatLogin from "@/components/login/WechatLogin.vue";
import PhoneLogin from "@/components/login/PhoneLogin.vue";
import QqLogin from "@/components/login/QqLogin.vue";
import VipPay from "@/components/pay/VipPay.vue";

export default {
    name: "ClientNav",
    components: {
        Minus,
        Close,
        DownloadList,
        WechatLogin,
        PhoneLogin,
        QqLogin,
        VipPay
    },
    methods: {
        // 最小化窗口
        minWindow() {
            ipc.minWindow() ;
        },
        // 最大化窗口切换
        maxWindow() {
            ipc.maxWindow() ;
        },
        // 关闭窗口
        closeWindow() {
            ipc.closeWindow() ;
        }
    }
};
</script>

<style scoped lang="scss">
/* 最外层 div */
.client-nav {
    -webkit-app-region: drag;
    position: sticky;
    top: 0px;
    width: 100%;
    height: 30px;
    z-index: 100;
    background: #E4E7EB;
}

/* 客户端点击区域 */
.client-item {
    -webkit-app-region: no-drag;
    display: flex;
    align-items: center;
    height: 100%;
}

.client-item-div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 30px;
    cursor: pointer;
}
.client-item-div:hover {
    background-color: #d6d9dd;
}
.client-item-div.close:hover {
    color: #fff;
    background-color: #FA5151;
}

.client-item-icon {
    width: 16px;
    height: 16px;
}
.client-item-icon.min {
    width: 13px;
    height: 13px;
}


</style>
