import { createStore } from 'vuex'

export default createStore({
  state: {
    // 设置滚动条位置
    setScrollTop: null,
    // 触发底线加载更多方法
    bottomLineLoad: null,
    // 是否登录
    isLogin: false,
    // 微信登录界面是否显示
    wechatLoginShow: false,
    // 读取微信登录状态
    getWxLoginStatus: null,
    // 手机号登录界面是否显示
    phoneLoginShow: false,
    // QQ登录界面是否显示
    qqLoginShow: false,
    // 读取QQ登录状态
    getQqLoginStatus: null,
    // 登录、退出后触发事件
    loginEvent: null,
    // 下载管理界面是否显示
    downloadShow: false,
    // 下载开始
    downloadStart: null,
    // VIP支付界面是否显示
    vipPayShow: false,
    // VIP支付触发事件
    vipPayInit: null
  },
  mutations: {
    setScrollTopVal(state, val) {
      state.setScrollTop = val ;
    },
    bottomLineLoadVal(state, val) {
      state.bottomLineLoad = val ;
    },
    isLoginVal(state, val) {
      state.isLogin = val ;
    },
    wechatLoginShowVal(state, val) {
      state.wechatLoginShow = val ;

      // 开关读取微信登录状态的定时
      if (state.getWxLoginStatus) {
        state.getWxLoginStatus(val) ;
      }
    },
    getWxLoginStatusVal(state, val) {
      state.getWxLoginStatus = val ;
    },
    phoneLoginShowVal(state, val) {
      state.phoneLoginShow = val ;
    },
    qqLoginShowVal(state, val) {
      state.qqLoginShow = val ;

      // 开关读取微信登录状态的定时
      if (state.getQqLoginStatus) {
        state.getQqLoginStatus(val) ;
      }
    },
    getQqLoginStatusVal(state, val) {
      state.getQqLoginStatus = val ;
    },
    loginEventVal(state, val) {
      state.loginEvent = val ;
    },
    downloadShowVal(state, val) {
      state.downloadShow = val ;
    },
    downloadStartVal(state, val) {
      state.downloadStart = val ;
    },
    vipPayShowVal(state, val) {
      state.vipPayShow = val ;

      // 开关触发初始化方法
      if (state.vipPayInit) {
        state.vipPayInit(val) ;
      }
    },
    vipPayInitVal(state, val) {
      state.vipPayInit = val ;
    }
  },
  actions: {
  },
  modules: {
  }
})
