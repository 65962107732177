<template>
    <div class="m-20">
        <div class="user-download-nav">
            <CategoryNav :category="category" @categoryEmit="watchCategory" />
        </div>

        <div class="user-download-list m-t-10">
            <div class="m-t-20" :class="[heightClass(item)]" v-for="(item, key) in listData" :key="key">
                <UserMaterial :material="item" @recommendEmit="recommendMaterial" />
            </div>
        </div>
        <div class="no-res-tip" v-if="emptyHint">
            <img src="https://cdn-prd.daozi.com/static/client/user/no_list.png" />
            <span>
                抱歉，没有相关素材！
            </span>
        </div>
    </div>

    <DetailSingle :show="recommendShow" :detailId="recommendId" @showEmit="recommendValue" @favoriteEmit="favoriteValue" @recommendEmit="recommendSingle" />
</template>

<script>
import CategoryNav from "@/components/category/CategoryNav.vue" ;
import UserMaterial from "@/components/material/UserMaterial.vue" ;
import DetailSingle from "@/components/material/DetailSingle.vue";

export default {
    name: "UserDowload",
    components: {
        CategoryNav,
        UserMaterial,
        DetailSingle
    },
    data() {
        return {
            listData: [],
            category: {type: 0, scene: 0, purpose: 0},
            page: 1,
            loadMoreAllow: true,
            recommendShow: false,
            recommendId: 0,
            emptyHint: false
        };
    },
    created() {
        // 素材列表
        this.getListData() ;
    },
    computed: {
        // 不同分类设置的高度不同
        heightClass() {
            return (material) => {
                let resClass = 'waterfall-common' ;

                if (material.type === 2) {
                    resClass = 'waterfall-high' ;
                }
                if (material.type === 4 && material.scene === 24) {
                    resClass = 'waterfall-high' ;
                }
                if (material.type === 4 && material.scene === 19) {
                    resClass = 'waterfall-inbetweening' ;
                }

                return resClass ;
            }
        }
    },
    methods: {
        // 分类变更
        watchCategory() {
            this.page = 1 ;
            this.listData = [] ;
            this.getListData() ;
        },
        // 读取素材列表
        getListData() {
            const url = this.$conf.apiUrl + '/client/Person/downloadList' ;

            this.$axios.post(url, this.$qs.stringify({
                token: this.$getUserInfo().token,
                type_id: this.category.type,
                scene_id: this.category.scene,
                purpose_id: this.category.purpose,
                page: this.page
            })) .then((res) => {
                this.listData.push.apply(this.listData, res.data.data) ;
                this.loadMoreAllow = true ;

                if (this.listData.length === 0) {
                    this.emptyHint = true ;
                } else {
                    this.emptyHint = false ;
                }
            }) ;
        },
        // 推荐变更（material）
        recommendMaterial(recommendId) {
            this.recommendId = recommendId ;
        },
        // 推荐变更（single）
        recommendSingle(recommendId) {
            this.recommendId = recommendId ;
            this.recommendShow = false ;
        },
        // 推荐界面显示状态
        recommendValue(data) {
            this.recommendShow = data ;
        },
        // 推荐详情页收藏状态变更触发事件
        favoriteValue(recommendId) {
            for (let val of this.listData) {
                if (val.id === recommendId) {
                    val.saved = !val.saved ;
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
/* 素材列表 */
.user-download-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 227px);
    grid-auto-flow: row dense;
    grid-auto-rows: 21px;
    justify-content: space-between;
}

@media only screen and (max-width: 1850px) {
    .user-download-list {
        grid-template-columns: repeat(auto-fill, 185px);
        grid-auto-rows: 20px;
    }
}
@media only screen and (max-width: 1450px) {
    .user-download-list {
        grid-template-columns: repeat(auto-fill, 165px);
        grid-auto-rows: 24px;
    }
}

.waterfall-common {
    grid-row: auto / span 15;
}
.waterfall-high {
    grid-row: auto / span 18;
}
.waterfall-inbetweening {
    grid-row: auto / span 17;
}
@media only screen and (max-width: 1850px) {
    .waterfall-common {
        grid-row: auto / span 13;
    }
    .waterfall-high {
        grid-row: auto / span 16;
    }
    .waterfall-inbetweening {
        grid-row: auto / span 15;
    }
}
@media only screen and (max-width: 1450px) {
    .waterfall-common {
        grid-row: auto / span 10;
    }
    .waterfall-high {
        grid-row: auto / span 12;
    }
    .waterfall-inbetweening {
        grid-row: auto / span 11;
    }
}

/* 没有素材时的提示 */
.no-res-tip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
    text-align: center;
    font-size: 26px;
    font-family: Microsoft YaHei;
    color: #666666;
}

</style>
